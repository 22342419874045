/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Dim, Rect } from '@/geom';
import * as logger from '@/logger';
import { createCanvas } from '@/util';

import type { Piece } from '@/piece';

type InitOptions = {
  columns: number;
  rows: number;
  minPieceDim: Readonly<Dim>;
  image?: HTMLImageElement;
};

const Padding = {
  w: 8,
  h: 8,
};

const tempRect = new Rect();

export class Cacher {

  public debug = {
    showGrid: false,
    showBounds: false,
    showIDs: false,
    noShading: false,
    showCoords: false,
  };

  private image: HTMLImageElement|null = null;
  private canvas: HTMLCanvasElement | null = null;
  private map: Map<string,boolean> = new Map();
  private rows = 0;
  private columns = 0;
  private minPieceDim = new Dim();
  private width = 0;
  private height = 0;
  private grid: Dim = new Dim();

  public init({ image, rows, columns, minPieceDim }: InitOptions) {
    this.rows = rows;
    this.columns = columns;

    if (minPieceDim) {
      this.minPieceDim.set(minPieceDim);
    }
    logger.debug(`cacher minPieceDim = ${this.minPieceDim}`);

    if (image) {
      this.setImage(image);
    } else if (this.image) {
      this.setImage(this.image);
    } else {
      throw new Error(`Cacher init called but there is no image to use`);
    }
  }

  public setImage(image: HTMLImageElement) {
    this.image = image;
    this.calcDimensions();
    this.canvas = createCanvas(this.width, this.height);

    if (!this.canvas) {
      throw new Error(`Unable to create backing canvas`);
    }

    const context = this.canvas.getContext('2d');

    if (!context) {
      throw new Error(`Unable to get backing context`);
    }

    context.strokeStyle='black';
    context.lineWidth = 2;
  }

  public has(piece: Piece) {
    return this.map.has(piece.id);
  }

  public get(piece: Piece) : { rect: Readonly<Rect>; image: CanvasImageSource } {
    if (!this.has(piece)) {
      throw new Error(`Cacher cache miss for piece ${piece.id}`);
    }
    if (!this.canvas) {
      throw new Error(`No backing canvas for Cacher to return`);
    }

    const rect = this.getPieceBlitRect(piece);

    return {
      rect,
      image: this.canvas,
    };
  }

  public put(piece: Piece) {
    this.drawPiece(piece);
    this.map.set(piece.id, true);
  }

  public getCanvasImage() {
    return this.canvas?.toDataURL();
  }

  private calcDimensions() {
    this.grid.set(this.minPieceDim.w + Padding.w, this.minPieceDim.h + Padding.h);
    this.width = this.columns * this.grid.w;
    this.height = this.rows * this.grid.h;
    logger.debug('cacher grid', this.grid);
  }

  private getPieceGridRect(piece: Piece) : Readonly<Rect> {
    tempRect.set(
      piece.column * this.grid.w,
      piece.row * this.grid.h,
      this.grid.w,
      this.grid.h,
    );

    return tempRect;
  }

  private getPieceBlitRect(piece: Piece) : Rect {
    const rect = this.getPieceGridRect(piece).clone();
    rect.offset(Padding.w / 2, Padding.h/2).floor();
    const b = piece.getBoundingRect();
    rect.width = b.w;
    rect.height = b.h;

    return rect;
  }

  private getGridRectAsPath(piece: Piece) {
    const gridRect = this.getPieceGridRect(piece);
    const path = new Path2D();
    path.rect(0, 0, gridRect.w, gridRect.h);

    return path;
  }

  private calcBlitRects(piece: Piece) {
    const translate = this.getPieceGridRect(piece).pos.clone();
    translate.add(Padding.w / 2, Padding.h/2).floor();

    const source = piece.sourceRect.clone();
    const dest = piece.getBoundingRect().clone();
    source.pos.sub(piece.offset);
    dest.pos.set(0, 0);

    return {
      translate,
      source, dest,
    };
  }

  private drawPieceShading(path: Path2D, context: CanvasRenderingContext2D) {
    // draw shading
    const offset = 3;

    if (!this.debug.noShading) {
      // context.translate(offset, offset);
      // context.filter = `blur(${offset}px)`;
      // context.strokeStyle = 'rgba(255, 255, 255, 0.3)';
      // context.lineWidth = offset;
      // context.stroke(path);
      // context.translate(-offset, -offset);

      context.translate(-offset, -offset);
      context.filter = `blur(${offset}px)`;
      context.lineWidth = offset / 2;
      context.strokeStyle = 'rgba(0, 0, 0, 0.6)';
      context.stroke(path);

      context.filter = 'none';
      context.lineWidth = 1;
      context.translate(offset, offset);
      context.strokeStyle = 'rgba(0, 0, 0, 0.5)';
      context.stroke(path);
    } else {
      context.lineWidth = 1;
      context.strokeStyle = 'rgba(0, 0, 0, 0.5)';
      context.stroke(path);
    }
  }

  private drawDropShadow(path: Path2D, context: CanvasRenderingContext2D) {
    const shadowOffset = 5;
    context.translate(shadowOffset, shadowOffset);
    context.filter = `blur(${shadowOffset}px)`;
    context.fillStyle = '#F0F';
    context.fill(path);
    context.filter = 'none';
    context.translate(-shadowOffset, -shadowOffset);
  }

  private drawPiece(piece: Piece) {
    const context = this.canvas?.getContext('2d');
    if (!context) {
      throw new Error(`Unable to get canvas context`);
    }
    if (!this.image) {
      throw new Error(`Cannot drawPiece with no image`);
    }

    const { source, dest, translate } = this.calcBlitRects(piece);
    // logger.debug(`draw piece ${piece.id}:`);
    // logger.debug(`- translate to (${translate.x}, ${translate.y})`);
    // logger.debug(`- draw image from (${source.x}, ${source.y} ${dest.w}×${dest.h}) at 0,0 (translated) ${dest.dimensions}`);

    const path : Path2D = piece.toPath({ offset: piece.offset });
    context.save();
    context.translate(translate.x, translate.y);
    // this.drawDropShadow(path, context);
    context.clip(path);
    context.drawImage(this.image,
      // source rect
      source.x, source.y, dest.width, dest.height,
      // dest rect
      0, 0, dest.width, dest.height,
    );

    this.drawPieceShading(path, context);
    context.restore();

    if (this.debug.showIDs) {
      context.save();
      context.translate(translate.x+2, translate.y + 20);
      context.fillStyle = '#030';
      context.textAlign = 'left';
      context.textBaseline = 'top';
      context.font = 'bold 15px monospace';
      context.fillText(piece.id, 0, 0);
      context.restore();
    }

    if (this.debug.showGrid) {
      context.strokeStyle = 'black';
      const gridRect = this.getPieceGridRect(piece);
      context.strokeRect(gridRect.topLeft.x, gridRect.topLeft.y, gridRect.w, gridRect.h);
    }

    if (this.debug.showBounds) {
      const bounds = piece.getBoundingRect();

      const b = bounds.clone();
      b.pos.add(b.pos.times(-1)).add(translate);
      context.strokeStyle = '#f80';
      context.strokeRect(b.x, b.y, b.w, b.h);
    }

    if (this.debug.showCoords) {
      context.font = '12px "Open Sans"';
      context.fillStyle = '#030';
      context.strokeStyle = '#eee';
      context.fillText(`${piece.sourceRect.topLeft.x}, ${piece.sourceRect.topLeft.y}`,
        translate.x, translate.y + 40);
    }
  }
}
