/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { IndexableRO } from '@/jtypes';

/**
 * Map an object of { className => boolean ...} to a string
 * @param  {object} map Something that can be cast as Indexable
 * @return {string}     Space-separated class names
 */
export default function classNames(map: object) : string {
  const indexableMap = map as IndexableRO<boolean>;

  if (!indexableMap) {
    throw new Error(`Unable to index map`);
  }

  return Object.keys(indexableMap)
    .reduce(
      (list: string[], k: string) => (indexableMap[k] ? list.concat(k) : list),
      [],
    )
    .join(' ');
}
