/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { NullEventEmitter } from '@/eventEmitter';
import { PieceGrouper } from '@/pieceGrouper';
import { JigsawDrawMode, JigsawMode } from './index';

import type { Point } from '@/geom/point';
import type { Perturber } from '@/perturbers/types';
import type { Piece } from '@/piece';
import type { AbstractRenderer } from '@/renderer';
import type { FindPieceOptions, IJigsaw, MovePieceOptions } from './types';


export class NullJigsaw extends NullEventEmitter implements IJigsaw {
  public columns = 0;
  public rows = 0;
  public readonly groups = new PieceGrouper();
  public readonly mode = new JigsawMode();
  public readonly drawMode = new JigsawDrawMode();

  constructor() {
    super();
  }

  public *[Symbol.iterator]() : Generator<Piece, void, boolean|undefined> {/*NOOP*/}

  public setSize(columns: number, rows: number, { reset } = { reset: true }) { /* NOOP */ }
  public setImage(image: HTMLImageElement, { reset } = { reset: true }) { /* NOOP */ }

  public init(image: HTMLImageElement) : void { /* NOOP */ }

  public draw(renderer: AbstractRenderer, options?: JigsawDrawMode) : void { /* NOOP */ }

  public getPiece(column: number, row: number) : Piece {
    throw new Error(`Not implemented on NullJigsaw`);
  }

  public findPieceByPoint(point: Readonly<Point>, { reverse = false, onlyEdges = false }: FindPieceOptions = {}) : Piece|false {
    return false;
  }

  public movePieceBy(piece: Piece, dx: number, dy: number, options?: MovePieceOptions) : void { /* NOOP */ }
  public movePieceTo(piece: Piece, x: number, y: number, options?: MovePieceOptions) : void { /* NOOP */ }
  public raisePieceToTop(piece: Piece, options?: MovePieceOptions) { /* NOOP */ }
  public lowerPieceToBottom(piece: Piece, options?: MovePieceOptions) { /* NOOP */ }

  public perturb(p: Perturber) : void { /* NOOP */ }

  public isDirty() : boolean { return false; }

  public markAsDirty() : void { /* NOOP */ }
  public markPieceDirty(piece: Piece) { /* NOOP */ }

  public getHint() : [Piece, Piece] {
    throw new Error(`Not implemented on NullJigsaw`);
  }

  public highlightPiece(renderer: AbstractRenderer, piece: Piece) { /* NOOP */ }
}
