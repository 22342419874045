// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".index__infoCollapsed___1FEGO{max-width:1rem;min-width:initial !important;overflow:hidden}.index__errorButton___1DIjF{font-size:.875em;background-color:#b55a62;border-radius:4px;border:1px solid #2e1517;-webkit-box-shadow:0px 6px 10px -6px #75343a;box-shadow:0px 6px 10px -6px #75343a;color:#fff;cursor:pointer;display:inline-block;font-weight:bold;padding:.5rem 1rem;text-decoration:none;text-shadow:0px 1px 0px #98444b;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.index__errorButton___1DIjF:active:not([disabled]){-webkit-transform:translateY(1px);transform:translateY(1px);-webkit-box-shadow:0px 4px 7px -5px #75343a;box-shadow:0px 4px 7px -5px #75343a}.index__errorButton___1DIjF:hover:not([disabled]),.index__errorButton___1DIjF:focus:not([disabled]){background-color:#da3f4c}@media screen and (max-device-width: 600px){.index__errorButton___1DIjF{padding:1rem;width:auto;height:auto}}@media screen and (max-device-height: 600px){.index__errorButton___1DIjF{padding:1rem;width:auto;height:auto}}", ""]);
// Exports
exports.locals = {
	"infoCollapsed": "index__infoCollapsed___1FEGO",
	"errorButton": "index__errorButton___1DIjF"
};
export default exports;
