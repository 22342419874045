/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { svgScript } from '@/svgscript';

import { AbstractRenderer } from './abstractRenderer';

import type { Point, Rect } from '@/geom';
import type { Indexable } from '@/jtypes';

type SVGStyle = {
  fillStyle: string;
  font: string;
  lineWidth: number;
  strokeStyle: string;
  textAlign: string;
};

type SVGPath = Indexable<string|string[]|number|undefined> & {
  class?: string;
  cx?: number;
  cy?: number;
  d?: string[];
  dx?: number;
  dy?: number;
  fill?: string;
  id?: string;
  lengthAdjust?: number;
  lineWidth?: number|string;
  r?: number;
  rotate?: number;
  stroke?: string;
  style?: string;
  tag: string;
  textLength?: number;
  x?: number;
  y?: number;
};

export class SVGRenderer extends AbstractRenderer {
  private currentPath: SVGPath = { tag: 'path', d: [] };
  private paths: SVGPath[] = [];

  private stack: SVGStyle[] = [];

  private style: SVGStyle = {
    fillStyle: 'black',
    strokeStyle: 'black',
    lineWidth: 1,
    textAlign: 'left',
    font: '14px sans-serif',
  };

  constructor(private viewBox: Rect) {
    super();
  }

  get fillStyle() : string | CanvasGradient | CanvasPattern {
    return this.style.fillStyle;
  }

  set fillStyle(style: string | CanvasGradient | CanvasPattern) {
    if (typeof (style) === 'string') {
      this.style.fillStyle = style;
    }
  }

  get font() : string {
    return this.style.font;
  }

  set font(style: string) {
    this.style.font = style;
  }

  get lineWidth() : number {
    return this.style.lineWidth;
  }

  set lineWidth(style: number) {
    this.style.lineWidth = style;
  }

  get strokeStyle() : string | CanvasGradient | CanvasPattern {
    return this.style.strokeStyle;
  }

  set strokeStyle(style: string | CanvasGradient | CanvasPattern) {
    if (typeof (style) === 'string') {
      this.style.strokeStyle = style;
    }
  }

  get textAlign() : string {
    return this.style.textAlign;
  }

  set textAlign(style: string) {
    this.style.textAlign = style;
  }

  public beginPath() {
    if (this.currentPath?.d?.length) {
      this.paths.push(this.currentPath);
      this.currentPath = { tag: 'path', d: [] };
    }
  }
  public bezierCurveTo(cp1: Point, cp2: Point, p: Point) {
    if (!this.currentPath.d) {
      throw new Error(`Current elt '${this.currentPath.tag}' is not a path`);
    }
    this.currentPath.d.push(`C${cp1.toSVG()} ${cp2.toSVG()} ${p.toSVG()}`);
  }

  public clearRect(x: number, y: number, w: number, h: number) {
    throw new Error('SVGRenderer cannot (easily) implement clearRect');
  }

  public closePath() {
    if (!this.currentPath.d) {
      throw new Error(`Current elt '${this.currentPath.tag}' is not a path`);
    }
    this.currentPath.d.push('z');
    this.paths.push(this.currentPath);
    this.currentPath = { tag: 'path', d: [] };
  }

  public ellipse(x: number, y: number, w: number, h: number, th: number, start: number, end: number) {
    throw new Error(`Not implemented`);
  }
  public fill(path?: Path2D) {
    if (path) {
      throw new Error(`SVGRenderer.fill cannot take '${typeof(path)}' type argument`);
    }
    this.currentPath.fill =this.style.fillStyle;
  }

  public fillRect(x: number, y: number, w: number, h: number) : void {
    this.paths.push({ tag: 'rect', x, y, w, h, fill: this.style.fillStyle });
  }

  public fillText(text: string, x: number, y: number) : void {
    this.paths.push({ tag: 'text', x, y });
  }

  public getCurrentPathData() {
    return this.currentPath?.d?.join(' ');
  }
  public lineTo(x: number , y: number) {
    if (!this.currentPath.d) {
      throw new Error(`Current elt '${this.currentPath.tag}' is not a path`);
    }
    this.currentPath.d.push(`L${x} ${y}`);
  }

  public moveTo(x: number , y: number) {
    if (!this.currentPath.d) {
      throw new Error(`Current elt '${this.currentPath.tag}' is not a path`);
    }
    this.currentPath.d.push(`M${x} ${y}`);
  }

  public restore() {
    this.style = this.stack.pop() ?? this.style;
  }


  public save() {
    this.stack.push(this.style);
    this.style = { ...this.style };
  }
  public stroke(path?: Path2D) {
    if (path) {
      throw new Error(`SVGRenderer.stroke cannot take '${typeof(path)}' type argument`);
    }
    this.currentPath.stroke = this.style.strokeStyle;
  }

  public strokeRect(x: number, y: number, w: number, h: number) : void {
    this.paths.push({ tag: 'rect', x, y, w, h, stroke: this.style.strokeStyle });
  }

  // getCurrentPathAsElement() {
  //   const elt = document.createElement('path');
  //   const { d, ...attr } : SVGPath  = this.currentPath;
  //   elt.setAttribute('d', this.getCurrentPathData());
  //   for (let k in attr) {
  //     elt.setAttribute(k, (attr[k] as string));
  //   }
  //   return elt;
  // }

  public toString(isFragment = false) {
    const pathToSVG = (p: SVGPath) => {
      const { tag, d, ...attr } = p;

      return `<${tag} ${Object.keys(attr).map(k => `${k}="${attr[k]}"`).join(' ')} d="${d?.join(' ')}" />`;
    };

    if (isFragment) {
      return `<svg viewBox="${[...this.viewBox].join(' ')}" xmlns="http://www.w3.org/2000/svg">
        ${this.paths.map(pathToSVG)}
</svg>`;
    }

    const clips = this.paths.map((p, idx) => {
      const id : string = `path_${idx}`;

      return `<clipPath id="${id}">${pathToSVG(p)}</clipPath>`;
    });

    const uses = this.paths.map((p, idx) => {
      const id : string = `path_${idx}`;

      return `<use href="#image" clip-path="url(#${id})" />`;
    });

    return `
      <svg viewBox="${[...this.viewBox].join(' ')}" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <image id="image" x="0" y="0" href="kit3.jpg" />
          ${clips.join('\n')}
        </defs>
        <g id="base">
          ${uses.join('\n')}
        </g>
        <g id="top" style="opacity: 0; pointer-events: none">
          <use id="ghost" href="#image" clip-path="url(#path_0)" />
        </g>
        <script type="text/javascript">
        ${svgScript}
        </script>
      </svg>
    `;
  }

}
