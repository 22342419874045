/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Immutable } from '@/jtypes';
import type { PointInit, PointInterface, XY } from './types';

export class Point implements PointInterface {
  private static _tmp = new Point();

  public static add(p1: Point, xOrPoint: number|Readonly<XY>, y?: number) : Point {
    return p1.clone().add(xOrPoint, y);
  }

  public static ceil(p: Point) : Point {
    return p.clone().ceil();
  }

  public static create(xOrPoint: number|Readonly<XY> = 0, y: number = 0) : Point {
    return new Point(xOrPoint, y);
  }

  public static floor(p: Point) : Point {
    return p.clone().floor();
  }

  // public static fromArgs(...xy: PointInit) : PointInterface {
  //   if (xy.length === 2) {
  //     point._tmp.x = xy[0];
  //     point._tmp.y = xy[1];
  //   } else if (xy.length === 1) {
  //     point._tmp.x = xy[0].x;
  //     point._tmp.y = xy[0].y;
  //   }

  //   return Point._tmp;
  // }

  public static map(p: Readonly<Point>, fn: (x: number) => number) : Point {
    return p.clone().map(fn);
  }

  public static mul(v: Point, factor: number) : Point {
    return v.clone().scale(factor);
  }

  public static random(width: number, height: number) : Point {
    return new Point(
      Math.random() * width,
      Math.random() * height,
    );
  }

  public static rotateAround(p: Immutable<Point>, { origin, radians }: { origin: PointInterface; radians: number }) : Point {
    return p.clone().rotateAround({ origin, radians });
  }

  public static scale(v: Point, factor: number) : Point {
    return v.clone().scale(factor);
  }

  public static sub(p1: Point, p2OrX: XY|number, y?: number) : Point {
    return p1.clone().sub(p2OrX, y);
  }

  public x: number = 0;
  public y: number = 0;


  constructor(xOrP: number|Readonly<XY> = 0, y: number = 0) {
      if (typeof xOrP === 'object') {
        this.x = (xOrP as XY).x ?? 0;
        this.y = (xOrP as XY).y ?? 0;
      } else {
        this.x = xOrP ?? 0;
        this.y = y ?? 0;
      }
  }

  public *[Symbol.iterator]() : Generator<number, void, void> {
    yield this.x;
    yield this.y;
  }

  public add(xOrPoint: XY|number, y?: number) : this {
    if (typeof (xOrPoint) === 'number') {
      if (y === undefined) {
        throw new Error(`add was passed (${xOrPoint}, undefined)`);
      }
      this.x += xOrPoint as number;
      this.y += y;
    } else {
      this.x += (xOrPoint as XY).x;
      this.y += (xOrPoint as XY).y;
    }

    return this;
  }

  public ceil() : this {
    return this.map(Math.ceil);
  }

  public clone() : this {
    return Point.create(this.x, this.y) as this;
  }

  public floor() : this {
    return this.map(Math.floor);
  }

  public isZero() {
    return this.x === this.y && this.x === 0;
  }

  public map(fn: (x: number) => number) : this {
    this.x = fn(this.x);
    this.y = fn(this.y);

    return this;
  }

  public minus(xOrPoint: XY|number, y?: number) : this {
    return Point.sub(this, xOrPoint, y) as this;
  }

  public plus(xOrPoint: number|XY, y?: number) : this {
    return Point.add(this, xOrPoint, y) as this;
  }

  public rotateAround({ origin, radians }: { origin: PointInterface; radians: number }) : this {
    const {x, y} = this.minus(origin);
    const ct = Math.cos(radians);
    const st = Math.sin(radians);
    this.x = x * ct - y * st + origin.x;
    this.y = x * st + y * ct + origin.y;

    return this;
  }

  public scale(factor: number) : this {
    this.x *= factor;
    this.y *= factor;

    return this;
  }

  public set(xOrPoint: XY|number, y?: number) : this {
    if (typeof (xOrPoint) === 'number') {
      if (y === undefined) {
        throw new Error(`set was passed (${xOrPoint}, undefined)`);
      }
      this.x = xOrPoint as number;
      this.y = y;
    } else {
      this.x = (xOrPoint as XY).x;
      this.y = (xOrPoint as XY).y;
    }

    return this;
  }

  public sub(xOrPoint: XY|number, y?: number) : this {
    if (typeof (xOrPoint) === 'number') {
      if (y === undefined) {
        throw new Error(`sub was passed (${xOrPoint}, undefined)`);
      }
      this.x -= xOrPoint as number;
      this.y -= y;
    } else {
      this.x -= (xOrPoint as XY).x;
      this.y -= (xOrPoint as XY).y;
    }

    return this;
  }

  public times(factor: number) : this {
    return this.clone().scale(factor);
  }

  public toArray() : [number, number] {
    return [this.x, this.y];
  }

  public toString() {
    return `(${this.x}, ${this.y})`;
  }

  public toSVG() {
    return `${this.x} ${this.y}`;
  }
}

