/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

// https://davidwalsh.name/javascript-debounce-function
// from underscore.js
// returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// n milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export default function debounce(func: () => void, wait: number, immediate: boolean = false) {
  let timerID : any = null;

    // @ts-ignore
  return function(...args: []) {
    // @ts-ignore
    const context : any = this;

    const later = () => {
      timerID = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timerID;

    clearTimeout(timerID);
    timerID = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}
