/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */


import type { IJigsaw } from '@/jigsaw';
import type { Perturber } from '@/perturbers';

import { JigsawCommand } from './jigsawCommand';
import { IJigsawCommand, Named } from './types';

export class PerturbCommand extends JigsawCommand {
  constructor(private perturber: Perturber) {
    super();
  }

  public run(jigsaw: IJigsaw) {
    jigsaw.perturb(this.perturber);
  }
}

