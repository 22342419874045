/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import { AppCommand } from './appCommand';

export enum ToggleMode {
  Toggle,
  On,
  Off,
}

export class ToggleEdgesCommand extends AppCommand {
  public static Mode = ToggleMode;

  constructor(private toggleMode: ToggleMode) {
    super();
  }

  public run(app: ReactJigsaw) {
    if (this.toggleMode === ToggleMode.Toggle) {
      app.setShowOnlyEdges(!app.getShowOnlyEdges());
    } else {
      app.setShowOnlyEdges(this.toggleMode === ToggleMode.On);
    }
  }
}

