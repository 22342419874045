/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { imageList } from '@/imageList';
import { randomElement } from '@/util';

export default function randomImage() {
  return randomElement(imageList);
}
