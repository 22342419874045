/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

export const joinSounds = [
  'button2.wav',
  'button3.wav',
  'button4.wav',
  'button5.wav',
  'button.wav',
];

export const successSounds = [
  'fanfare1.ogg',
  'fanfare2.ogg',
  'fanfare3.ogg',
];
