/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Piece } from '@/piece';

import { Point, Vec } from '@/geom';

export type Force = {
  force: Vec;
  pos: Point;
  radius: number;
  radiusSq: number;
  strength: number;
};

const radius = 40;
const radiusSq = radius * radius;
const limit = (radius * 2) * (radius * 2);

function dfunk(p: Readonly<Point>, O: Readonly<Point>) {
  // const O = new Point(width, height).scale(0.5);
  const vC = Vec.fromTwoPoints(O, p);// new Vec(p.x - O.x, p.y - O.y);
  // vC.setLength(vC.length + Math.random());
  // const radius = pusher;
  let k = Math.sqrt(radiusSq / (vC.sqlen - radiusSq));
  k = Math.min(1, Math.max(0, k));

  return vC.sqlen > limit ? 0
  : (vC.sqlen <= radiusSq) ? 1 : k;

  // // const distance = radius - vC.length;
  // // const distanceSq = distance * distance;
  // const distanceSq = radiusSq - vC.sqlen;

  // k = 1;

  // // if (distanceSq >= radiusSq) {
  //   const outer = radiusSq*2;
  //   // const dist = Math.sqrt(distanceSq);
  //   k = (distanceSq - radiusSq) / distanceSq;

  //   // }
  // return k;
}

export function calcForceForPiece(piece: Piece, forces: Force[], maxForce = 90) {
  const totalForce = forces.reduce(
    (accumulatedForce, f) => {
      // const vC = Vec.fromTwoPoints(f.pos, piece.pos);
      const b = piece.getBoundingRect();
      const k = dfunk(b.center, f.pos);
      const ff = f.force.times(k * f.strength * Math.sqrt(f.force.length));
      // ff.limitLength(f.maxForce);
      accumulatedForce.add(ff);

      const vAway = Vec.fromTwoPoints(f.pos, b.center);

      if (vAway.sqlen <= f.radiusSq) {
        accumulatedForce.add(vAway.scale(5 / vAway.length));
      }

      // blob.f = accumulatedForce.sqlen;
      return accumulatedForce;
    },
    new Vec(0, 0),
  );

  return totalForce.limitLength(maxForce);
}
