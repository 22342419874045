/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Point } from '@/geom/point';
import { AbstractRenderer } from './abstractRenderer';

export class NullRenderer extends AbstractRenderer {
  get fillStyle() : string | CanvasGradient | CanvasPattern {
    return '';
  }
  set fillStyle(style: string | CanvasGradient | CanvasPattern) { /* NOOP */ }
  get font() : string {
    return '';
  }
  set font(style: string) { /* NOOP */ }
  get lineWidth() : number {
    return 0;
  }
  set lineWidth(style: number) { /* NOOP */ }
  get strokeStyle() : string | CanvasGradient | CanvasPattern {
    return '';
  }
  set strokeStyle(style: string | CanvasGradient | CanvasPattern) { /* NOOP */ }
  get textAlign() : string {
    return '';
  }
  set textAlign(style: string) { /* NOOP */ }
  public beginPath() : void { /* NOOP */ }
  public bezierCurveTo(cp1: Point, cp2: Point, p: Point) : void { /* NOOP */ }
  public clearRect(x: number, y: number, w: number, h: number) : void { /* NOOP */ }
  public closePath() : void { /* NOOP */ }
  public ellipse(x: number, y: number, w: number, h: number, th: number, start: number, end: number) : void { /* NOOP */ }
  public fill(color?: string|object) : void { /* NOOP */ }
  public fillRect(x: number, y: number, w: number, h: number) : void { /* NOOP */ }
  public fillText(text: string, x: number, y: number) : void { /* NOOP */ }
  public lineTo(x: number , y: number) : void { /* NOOP */ }
  public moveTo(x: number , y: number) : void { /* NOOP */ }
  public restore() : void { /* NOOP */ }
  public save() : void { /* NOOP */ }
  public stroke(color?: string|object) : void { /* NOOP */ }
  public strokeRect(x: number, y: number, w: number, h: number) : void { /* NOOP */ }
}
