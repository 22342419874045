/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect } from '@/geom';

import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import type { Perturber } from './types';

export class Shuffler implements Perturber {
  constructor(private area: Rect) {
  }

  public run(pieces: Piece[], jigsaw: IJigsaw) : void {

    for (const piece of pieces) {
      const newPos = Point.random(this.area.width, this.area.height);
      const bounds = piece.getBoundingRect();

      newPos.ceil();
      // piece.angle = randRange(0, 2 * Math.PI - Math.PI);
      jigsaw.movePieceTo(piece, newPos.x, newPos.y, {  dirty: false, groups: true  });
    }
  }
}

