/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect, Vec } from '@/geom';

import { Scatterer } from './scatterer';

import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import type { Perturber } from './types';

export class ScatterSelected extends Scatterer {
  constructor(private selected: Piece[]) {
    super();
  }

  public run(pieces: Piece[], jigsaw: IJigsaw) : void {
    const force = new Vec();
    const newPos = new Point();
    const showOnlyEdges = false /* @todo get from previously jigsaw.isDrawingOnlyEdges */;

    for(let iter=0; iter < 20; iter++) {
      for (const pieceToScatter of pieces) {
        if (showOnlyEdges && !pieceToScatter.isEdge) {
          continue;
        }

        if (jigsaw.groups.isPieceInAnyGroup(pieceToScatter)) {
          continue;
        }

        this.calcRepellingForce(pieceToScatter, this.selected, showOnlyEdges, force);

        newPos.set(pieceToScatter.getPos()).add(force);


        jigsaw.movePieceTo(pieceToScatter, newPos.x, newPos.y, { dirty: false, groups: false });
      }
    }
  }
}
