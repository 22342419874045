/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type * as React from 'react';

type SetStateFunc<P, S> = (prevState: Readonly<S>, props: Readonly<P>) => (S | null);
type SetStateArg<P, S> = Pick<S, keyof S> | SetStateFunc<P, S>;

export default function setStatePromised<P, S>(
  component: React.Component<P,S>, state: SetStateArg<P, S>,
) {
  return new Promise(resolve => component.setState(state, resolve));
}
