/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as React from 'react';

import type { ICommand } from '@/commands';
import { HasHelp } from '@/components/HasHelp';

import styles from '@/styles/commandbutton.scss';

type Props<T> = {
  command?: T;
  title?: string;
  shortcut?: string;
  disabled?: boolean;
  className?: string;
  icon?: string;
  showHelp?: boolean;
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
  onClick?(cmd?: T) : void;
};

export function CommandButton<T>(props: Props<T>) {
  const {
    onClick, command, tooltipSide, showHelp,
    title, shortcut, disabled, className='', icon,
  } = props;

  const computedTitle = `${title}${shortcut ? ` ⌨ ${shortcut.toUpperCase()}` : ''}`;

  return (
    <div className={`command-button ${className}`}>
      <HasHelp show={showHelp} side={tooltipSide}>
        <button
          title={computedTitle}
          data-mobile-title={title}
          disabled={disabled}
          onClick={onClick ? () => onClick(command) : undefined}
          className={`${icon ? 'icon' : ''} ${styles.button}`}>{icon||title}</button>
      </HasHelp>
    </div>
  );
}
