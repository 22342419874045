/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { DefaultJigsawSettings, MaxExtensionFactor, MidpointVFactor } from '@/constants';
import { Point, Vec } from '@/geom';
import { randRange } from '@/util';

import type { PointInterface } from '@/geom/types';
import type { Piece } from '@/piece';
import type { PieceSidePoints } from '@/piece';

export type TongueParams = {
  endWidth: number;
  extrusion: number;
  midpoint: number;
  sideExtrusion: number;
  sideSmooth: number;
  sideWidth: number;
  width: number;
};

export type TonguePoint = Point | [Point, Point, Point];

export enum TongueType {
  OUT,
  IN,
  NONE,
}


export class Tongue {

  public static random() {
    const invert = Math.random() > 0.5 ? -1 : 1;

    return new Tongue({
      extrusion: randRange(0, 1) * invert,
      width: randRange(0.0625, 0.5),
      endWidth: randRange(0, 1),
    });
  }
  public endWidth = 0;
  public extentPx = 0;
  public extrusion = 0;
  public midpoint = 0;
  public sideExtrusion = 0;
  public sideSmooth = 0;
  public sideWidth = 0;
  public width = 0;

  constructor(settings: Partial<TongueParams> = {}) {
    Object.assign(this, DefaultJigsawSettings, settings);
  }

  get isInnie() : boolean {
    return this.extrusion < 0;
  }


  /**
   * Calculate a list of points (or 3-point-tuples)
   * to draw the 'tongues'.
   * For each element in the returned list, it is
   * either a single point to draw a line to,
   * or a 3-tuple of points, in order [controlPoint1, controlPoint2, endPoint].
   *
   *            _±_e_±_
   *          |/       \|
   *          +         +
   *          |         |
   *           \       /
   * 1_________x\  m  /y._______2
   *
   * 1 = firstPt
   * 2 = lastPt
   * m = midpoint
   * x = tongueCornerPoint1
   * y = tongueCornerPoint2
   * ± = curvePt1 and 2
   * e = tongueEndPt
   * + = sidePt1, 2
   *
   * @param  {[type]}          firstPt [description]
   * @param  {PieceSidePoints} lastPt  [description]
   * @param  {Tongue}          tongue  [description]
   * @return {TonguePoint[]}           [description]
   */
  public calcPoints([ firstPt, lastPt ]: PieceSidePoints) : TonguePoint[] {

    const v = Vec.fromTwoPoints(firstPt, lastPt);
    const vLength = v.length;
    const vExtrusion : Vec = Vec.rotate90CCW(v).scale(this.extrusion);

    const vSides : Vec = vExtrusion.times(this.sideExtrusion);
    const midpoint = firstPt.plus(v.times(this.midpoint));
    const tongueCornerPt1 : Point = midpoint.minus(v.times(this.width));
    const tongueCornerPt2 : Point = midpoint.plus(v.times(this.width));
    const tongueEndPt : Point = midpoint.plus(vExtrusion);

    v.scale(this.endWidth);
    const curvePt1 : Point = tongueEndPt.minus(v);
    const curvePt2 : Point = tongueEndPt.plus(v);

    const sidePointExtrusion = vLength * this.sideWidth * (this.isInnie ? -1 : 1);
    const sidePt1 : Point = tongueCornerPt1
      .plus(vSides)
      .plus(Vec.rotate90CCW(vSides).setLength(sidePointExtrusion))
      ;
    const sidePt2 : Point = tongueCornerPt2
      .plus(vSides)
      .plus(Vec.rotate90CW(vSides).setLength(sidePointExtrusion))
      ;

    return [
      tongueCornerPt1.ceil(),
      [midpoint.plus(vExtrusion.times(MidpointVFactor)).ceil(), sidePt1.minus(vExtrusion.times(this.sideSmooth)).ceil(), sidePt1.ceil()],
      [sidePt1.plus(vExtrusion.times(this.sideSmooth)).ceil(), curvePt1.ceil(), tongueEndPt.ceil()],
      [curvePt2.ceil(), sidePt2.plus(vExtrusion.times(this.sideSmooth)).ceil(), sidePt2.ceil()],
      [sidePt2.minus(vExtrusion.times(this.sideSmooth)).ceil(), midpoint.plus(vExtrusion.times(MidpointVFactor)).ceil(), tongueCornerPt2.ceil()],
    ];
  }

  public extentInPixels(side: [PointInterface, PointInterface], piece: Piece) {
    this.extentPx = 0;
    const isInnie = this.isInnie;
    const v = Vec.fromTwoPoints(...side);
    const length = v.length;
    const isVerticalSide = side[0].x === side[1].x;
    const maxExtension = isVerticalSide ? piece.baseWidth * MaxExtensionFactor : piece.baseHeight * MaxExtensionFactor;
    this.extentPx = Math.ceil(Math.min(Math.abs(length * this.extrusion), maxExtension));
    this.extrusion = this.extentPx / length;
    this.extrusion *= isInnie ? -1 : 1;
    this.extentPx = isInnie ? 0 : this.extentPx;

    return this.extentPx;
  }

  public limitExtrusion(extrusion: number) {
    if (Math.abs(this.extrusion) > extrusion) {
      this.extrusion = this.extrusion * extrusion / Math.abs(this.extrusion);
    }
  }

}
