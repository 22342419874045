/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Rect } from '@/geom';
import type { ImageSource } from '@/imageLoader';

export function getImageFromLocation() : ImageSource|false {
  const params = new URLSearchParams(document.location.search.substring(1));
  const url : string|null = params.get('u');
  const cropStr : string|null  = params.get('c');

  if (!url) {
    return false;
  }

  let crop;

  if (cropStr) {
    try {
      crop = Rect.fromString(cropStr);
    } catch { /* NOOP */}
  }

  return { url, crop };
}
