/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import { JigsawCommand } from './jigsawCommand';

export class RaisePieceCommand extends JigsawCommand {
  constructor(private piece: Piece) {
    super();
  }

  public run(jigsaw: IJigsaw) {
    jigsaw.raisePieceToTop(this.piece);
  }
}
