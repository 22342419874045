/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect } from '@/geom';
import type { IJigsaw } from '@/jigsaw';
import type { Immutable } from '@/jtypes';
import type { Piece } from '@/piece';
import type { Perturber } from './types';

export class Constrainer implements Perturber {
  constructor(private bounds: Immutable<Rect>) {
  }

  public run(pieces: Piece[], jigsaw: IJigsaw) : void {

    const newPos = new Point();

    for(const piece of pieces) {
      const b = piece.getBoundingRect();

      if (b.bottomRight.x < this.bounds.x
        || b.bottomRight.y < this.bounds.y
        || b.topLeft.x >= this.bounds.bottomRight.x
        || b.topLeft.y >= this.bounds.bottomRight.y) {

        // constrain within board
        newPos.set(
          Math.max(this.bounds.topLeft.x, Math.min(this.bounds.width - b.w, b.pos.x)),
          Math.max(this.bounds.topLeft.y, Math.min(this.bounds.height - b.h, b.pos.y)),
        );
        jigsaw.movePieceTo(piece, newPos.x, newPos.y, {  dirty: false });
      }
    }
  }
}
