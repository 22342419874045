/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { EventEmitter } from '@/eventEmitter';
import type { Positional } from './types';
import { VerletBody, VerletOptions } from './verletBody';

export class Engine extends EventEmitter {
  private bodies: VerletBody[] = [];
  private diedThisFrame: VerletBody[] = [];
  private rebornThisFrame: VerletBody[] = [];
  private _isRunning = false;
  private lastDeltaTime: number|false = false;

  constructor(private timeScale = 0.000001) {
    super();
  }

  public *[Symbol.iterator]() {
    for (let i = 0; i < this.bodies.length; i++) {
      yield this.bodies[i].body;
    }
  }

  public addBody(body: Positional, options: Partial<VerletOptions> = {}) {
    const vBody = new VerletBody(body, options);
    this.bodies.push(vBody);
    this._isRunning = true;

    return vBody;
  }

  public forEachBody(callback: (item: Positional) => boolean|undefined) {
    let body;

    for(let i = 0; i < this.bodies.length; i++) {
      body = this.bodies[i];
      if (callback(body.body)) {
        break;
      }
    }
  }

  public forEachLiveBody(callback: (item: Positional) => boolean|undefined) {
    let body;

    for(let i = 0; i < this.bodies.length; i++) {
      body = this.bodies[i];
      if (body.isAlive) {
        if (callback(body.body)) {
          break;
        }
      }
    }
  }

  public forEachDiedThisFrame(callback: (item: Positional) => boolean|undefined) {
    for (let i = 0; i < this.diedThisFrame.length; i++) {
      const body = this.diedThisFrame[i];
      if (callback(body.body)) {
        break;
      }
    }
  }

  public forEachRebornThisFrame(callback: (item: Positional) => boolean|undefined) {
    let body;

    for(let i = 0; i < this.rebornThisFrame.length; i++) {
      body = this.rebornThisFrame[i];
      if (callback(body.body)) {
        break;
      }
    }
  }

  public clear() {
    this.bodies.length = 0;
    this.diedThisFrame.length = 0;
    this.rebornThisFrame.length = 0;
    this._isRunning = false;
  }

  public isRunning() {
    return this._isRunning;
  }

  public isBodyActive(body: Positional) {
    const { isAlive } = this.bodies.find(b => b.body === body) ?? { isAlive: false };

    return isAlive;
  }

  public removeBody(body: Positional) {
    const idx = this.bodies.findIndex(b => b.body === body);
    if (idx >= 0) {
      this.bodies.splice(idx, 1);
    }
  }

  /*
   * Run integration step
   *
   * Using:
   *
   *   https://www.lonesock.net/article/verlet.html
   *
   *   Time-Corrected Verlet:
   *
   *   xi+1 = xi + (xi - xi-1) * (dti / dti-1) + a * dti * dti
   *
   * @param {number} deltaTime Milliseconds since last update
   */
  public step(deltaTime: number) {
    if (this.lastDeltaTime === false) {
      this.lastDeltaTime = deltaTime;
    }
    const tSq = deltaTime * deltaTime;

    let wasAlive;
    let unaffected = 0;

    this.diedThisFrame.length = 0;
    this.rebornThisFrame.length = 0;

    let body;

    for(let i = 0; i < this.bodies.length; i++) {
      body = this.bodies[i];
      wasAlive = body.isAlive;
      body.update(tSq, this.lastDeltaTime === 0 ? 1 : deltaTime / this.lastDeltaTime);

      if (wasAlive && !body.isAlive) {
        this.diedThisFrame.push(body);
        // this.emit(wasAlive ? 'deactivate' : 'activate', body);
      } else if (!wasAlive && body.isAlive) {
        this.rebornThisFrame.push(body);
      } else if (!wasAlive && !body.isAlive) {
        ++unaffected;
      }
    }
    if (unaffected >= this.bodies.length) {
      if (this._isRunning) {
        this.emit('finished');
      }
      this._isRunning = false;
    } else {
      this._isRunning = true;
    }

    this.lastDeltaTime = deltaTime;
  }

  public clearVelocities() {
    let body;

    for(let i = 0; i < this.bodies.length; i++) {
      body = this.bodies[i];
      body.clearVelocity();
    }
  }
}
