/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import type { Immutable } from '@/jtypes';
import { BaseCommand } from './baseCommand';
import type { IAppCommand, ICommand, Named } from './types';

export abstract class AppCommand extends BaseCommand implements IAppCommand {
  constructor() {
    super();
  }

  public abstract run(app: ReactJigsaw) : void;
}

