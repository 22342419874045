/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */


import type { TongueParams } from '@/tongue';

// for the @/util/dot function
export const DefaultDotRadius = 7;

// spacing around screen edges
export const ControlsMargin = 25;

// spacing around jigsaw on canvas
export const CanvasMarginFactor = 0.2;

// initial image to load
export const DefaultImageURL = 'kit1.jpg';

// initial cut settings
export const DefaultNumPieces = 80;
export const DefaultPieceSize = 150;

/**
 * Fraction of base width/height that is maximum for tongue intrusion
 * @type {Number}
 */
export const MaxExtensionFactor = 0.48;

/**
 * Where do control points go for first point on tongue curve after corner
 * @type {Number}
 */
export const MidpointVFactor = 0.125;

export const DefaultJigsawSettings : TongueParams = {
  endWidth: 0.1,
  extrusion: 0.32,
  midpoint: 0.5,
  sideExtrusion: 0.6,
  sideSmooth: 0.33,
  sideWidth: 0.04,
  width: 0.15,
};

export const PhysicsTimeScale = 0.01;
export const MobileDeviceWidth = 600; // must be same as in styles/mixins.scss smallScreen
