/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Vec } from '@/geom';
import type { ConstrainFunc, Positional } from './types';

/**
 * Default friction factor if not otherwise set on body
 * @type {Number}
 */
const FrictionCoeff = 0.8;

export type VerletOptions = {
  constrain: ConstrainFunc;
  frictionCoeff: number;
  minActiveVelocity: number;
};

// used to avoid excess allocations
const _tempPos = new Point();

export class VerletBody {
  private static readonly MinVelocity = 1;
  public frictionCoeff: number;
  public isAlive = false;

  public lastPos = new Point();
  public velocity = new Vec();
  private constrain: ConstrainFunc;

  private hasRun = false;
  private minActiveVelocity: number = VerletBody.MinVelocity;

  constructor(public body: Positional, options: Partial<VerletOptions> = {}) {
    const {
      frictionCoeff = FrictionCoeff, constrain, minActiveVelocity,
    } = options;
    this.frictionCoeff = frictionCoeff;
    this.constrain = constrain || this.nop;
    this.minActiveVelocity = minActiveVelocity ?? this.minActiveVelocity;
  }

  public clearVelocity() {
    this.hasRun = false;
  }

  public nop(p: Point, body: VerletBody) {
    // nOP, allow for subclasses to extend
  }

  public update(tSq: number, tRatio: number = 1) {
    const pos = this.body.getPos();
    const { velocity } = this;

    if (!this.hasRun) {
      this.lastPos.set(pos);
      this.hasRun = true;
    }

    velocity.set(pos).sub(this.lastPos);
    velocity.scale(tRatio); // time-Corrected-Verlet (dti / dti-1)  @see engine.ts

    this.isAlive = velocity.sqlen > this.minActiveVelocity;

    velocity.scale(this.frictionCoeff);
    this.lastPos.set(pos);

    _tempPos.set(pos).add(velocity);
    // const newPos = pos.plus(velocity);
    this.constrain(_tempPos, this);
    this.body.setPos(_tempPos);
  }
}
