/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { NullEventEmitter } from './nullEventEmitter';
import type { EventEmitterCallback, Listener, ListenerMap, QueuedEvent } from './types';

const CanQueueEvents = false;

export class EventEmitter extends NullEventEmitter {
  private listeners: ListenerMap = {};
  private queue: QueuedEvent[] = [];
  private queueLimit: number;

  constructor({ queueLimit = 10 }: { queueLimit?: number } = {}) {
    super();
    this.queueLimit = queueLimit;
  }

  public chain(eventName: string, emitter: EventEmitter) {
    this.on(eventName, (data: any, type: string) => emitter.emit(type, data));
  }

  public chainOnce(eventName: string, emitter: EventEmitter) {
    this.once(eventName, (data: any, type: string) => emitter.emit(type, data));
  }

  public on(eventName: string, cb: EventEmitterCallback) : void {
    const eventListeners : Listener[] = this.listeners[eventName] ?? [];
    const existing : Listener|undefined = eventListeners.find(listener => listener.cb === cb);
    if (existing) {
      existing.isOnetime = false;
    } else {
      eventListeners.push({ cb, isOnetime: false, hasFired: false });
    }
    this.listeners[eventName] = eventListeners;
    this.processQueue();
  }

  public oncePromised(eventName: string) : Promise<any> {
    return new Promise(resolve => this.once(eventName, resolve));
  }

  public once(eventName: string, cb?: EventEmitterCallback) : Promise<any> {
    if (!cb) {
      return this.oncePromised(eventName);
    }
    const eventListeners = this.listeners[eventName] ?? [];
    const existing = eventListeners.find(listener => listener.cb === cb);
    if (existing) {
      existing.isOnetime = true;
    } else {
      eventListeners.push({ cb, isOnetime: true, hasFired: false });
    }
    this.listeners[eventName] = eventListeners;
    this.processQueue();

    return Promise.resolve();
  }

  public off(eventName: string, cb: EventEmitterCallback) : void {
    if (!(eventName in this.listeners)) {
      return;
    }
    const eventListeners = this.listeners[eventName];
    const existingIdx = eventListeners.findIndex(listener => listener.cb === cb);
    if (existingIdx >= 0) {
      eventListeners.splice(existingIdx, 1);
    }
  }

  public emit(eventName: string, data?: any) : boolean {
    if (!(eventName in this.listeners) && CanQueueEvents) {
      this.queue.push({ eventName, data });
      this.queue.length = this.queueLimit;

      return false;
    }

    return this.dispatch(eventName, data);
  }

  private processQueue() : void {
    this.queue = this.queue.filter(({ eventName, data }: QueuedEvent) => this.dispatch(eventName, data));
  }

  private dispatch(eventName: string, data: any) {
    if (!(eventName in this.listeners)) {
      return false;
    }
    // logger.debug('dispatching event', eventName);
    this.listeners[eventName].forEach(listener => {
      listener.cb(data, eventName);
      listener.hasFired = true;
    });

    this.listeners[eventName] = this.listeners[eventName].filter(
      (listener: Listener) => !(listener.isOnetime && listener.hasFired),
    );

    return true;
  }
}
