/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Rect } from '@/geom';
import type { Immutable } from '@/jtypes';

// import * as logger from '@/logger';

export interface HasBounds {
  getBoundingRect() : Immutable<Rect>;
}

export class DirtyRectangles {
  private regions: Rect[] = [];
  private totalArea: Rect = new Rect();

  constructor() {
    this.invalidate();
  }

  public isDirty(rect?: Immutable<Rect>) {
    if (!rect) {
      return !this.totalArea.isEmpty();
    } else {
      return this.totalArea.hasIntersect(rect) ? true : false;
    }
  }

  public invalidate() {
    this.regions.length = 0;
    this.totalArea.set(0, 0, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
    // logger.debug('dirty rectangles reset to fully dirty');
  }

  public getRegion() {
    return this.totalArea;
  }

  public markPieceDirty(piece: Immutable<HasBounds>) {
    const r = piece.getBoundingRect().clone();
    // logger.debug('piece marked dirty', (piece as Piece).id, r+'');
    r.outset(15);
    this.dirty(r);
  }

  public markPieceClean(piece: Immutable<HasBounds>) {
    const r = piece.getBoundingRect().clone();
    this.clean(r);
  }

  public dirty(rect: Immutable<Rect>) {
    const mergedInto : Rect[] = [];

    // find an existing region to merge rect into.
    for (let i = 0; i < this.regions.length; i++) {
      const r = this.regions[i];

      if (r.hasIntersect(rect)) {
        Rect.merge(r, rect, r);
        mergedInto.push(r);
      }
    }

    // if we didn't find existing region overlaps
    if (!mergedInto.length) {
      this.regions.push(rect.clone());
      Rect.merge(this.totalArea, rect, this.totalArea);
    } else {
      const target = mergedInto[0];

      // recombine regions since there's now an overlap
      for (let i = 1; i < mergedInto.length; i++) {
        const other = mergedInto[i];
        this.regions.splice(this.regions.indexOf(other), 1);
        Rect.merge(target, other, target);
      }

      Rect.merge(this.totalArea, target, this.totalArea);
      this.regions.push(target);
    }
  }

  public clean(rect: Immutable<Rect>) {
    const toAdd : Rect[] = [];

    this.totalArea.set(0, 0, 0, 0);

    this.regions = this.regions.filter((region: Rect) => {
      if (region.hasIntersect(rect)) {
        const newRects = Rect.split(region, rect);
        newRects.forEach(r => Rect.merge(this.totalArea, r, this.totalArea));
        toAdd.push(...newRects);

        return false;
      } else {
        Rect.merge(this.totalArea, region, this.totalArea);
      }

      return true;
    });

    this.regions.push(...toAdd);
  }

  public filter<T extends HasBounds>(list: Immutable<T>[]) {
    return list.filter((piece: Immutable<T>) => {
      const pieceRect = piece.getBoundingRect();

      if (!this.totalArea.hasIntersect(pieceRect)) {
        return false;
      }
      for (let i = 0; i < this.regions.length; i++) {
        const region = this.regions[i];
        if (region.hasIntersect(pieceRect)) {
          return true;
        }
      }

      return true;
    }) as T[];
  }

  public clear() {
    // logger.debug('dirty rectangles cleared');
    this.regions.length = 0;
    this.totalArea.set(0, 0, 0, 0); // empty dirty area
  }

  public debug(context: CanvasRenderingContext2D) {
    if (!this.isDirty()) {
      return;
    }
    const clean = Rect.split(this.getRegion(), Rect.fromWidthHeight(context.canvas.width, context.canvas.height));

    context.fillStyle = 'rgba(0,0,0,0.4)';
    context.strokeStyle = 'green';

    clean.forEach(r => {
      context.fillRect(r.x, r.y, r.w, r.h);
      context.strokeRect(r.x, r.y, r.w, r.h);
    });

    context.strokeStyle = 'red';
    context.strokeRect(this.totalArea.x+2, this.totalArea.y+2, this.totalArea.w-4, this.totalArea.h-4);

    context.strokeStyle = 'orange';
    for (let i = 0; i < this.regions.length; i++) {
      const r = this.regions[i];
      context.strokeRect(r.x, r.y, r.w, r.h);
    }
  }

}
