/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import { randomSound, randomSuccessSound } from '@/helpers/randomSound';
import { AppCommand } from './appCommand';

enum AudioCommandType {
  Snap,
  Success,
  Error,
}

export class AudioCommand extends AppCommand {
  public static Type = AudioCommandType;

  constructor(private type: AudioCommandType) {
    super();
  }

  public run(app: ReactJigsaw) {
    app.playSound(
      this.type === AudioCommandType.Snap ? randomSound() : randomSuccessSound(),
    );
  }
}
