/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Point } from '@/geom';
import type { Indexable, IndexableRO } from '@/jtypes';

import { AbstractRenderer } from './abstractRenderer';

export class CanvasRenderer extends AbstractRenderer {
  constructor(private context: CanvasRenderingContext2D | Path2D) {
    super();

    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.imageSmoothingEnabled = false;
    }
  }

  get fillStyle() : string | CanvasGradient | CanvasPattern {
    return this.getProp('fillStyle') as string;
  }

  set fillStyle(style: string | CanvasGradient | CanvasPattern) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.fillStyle = style;
    }
  }

  get font() : string {
    return this.getProp('font') as string;
  }

  set font(style: string) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.font = style;
    }
  }

  get lineWidth() : number {
    return this.getProp('lineWidth') as number;
  }

  set lineWidth(width: number) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.lineWidth = width;
    }
  }

  get strokeStyle() : string | CanvasGradient | CanvasPattern {
    return this.getProp('strokeStyle') as string;
  }

  set strokeStyle(style: string | CanvasGradient | CanvasPattern) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.strokeStyle = style;
    }
  }

  get textAlign() : string {
    return this.getProp('textAlign') as string;
  }

  set textAlign(style: string) {
    this.setProp('textAlign', style);
  }

  public beginPath() {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.beginPath();
    }
  }

  public bezierCurveTo(cp1: Point, cp2: Point, p: Point) {
    this.context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, p.x, p.y);
  }

  public clearRect(x: number, y: number, w: number, h: number) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.clearRect(x,y,w,h);
    }
  }

  public closePath() {
    this.context.closePath();
  }

  public ellipse(x: number, y: number, w: number, h: number, th: number, start: number, end: number) {
    this.context.ellipse(x, y, w, h, th, start, end);
  }

  public fill(path?: Path2D) {
    if (this.context instanceof Path2D) {
      throw new Error('Path2D cannot use fill');
    }
    if (path) {
      this.context.fill(path);
    } else {
      this.context.fill();
    }
  }

  public fillRect(x: number, y: number, w: number, h: number) {
    if (this.context instanceof Path2D) {
      throw new Error('Path2D cannot use fillRect');
    }
    this.context.fillRect(x, y, w, h);
  }

  public fillText(text: string, x: number, y: number) : void {
    if (this.context instanceof Path2D) {
      throw new Error('Unable to fill text in Path2D');
    }
    this.context.fillText(text, x, y);
  }

  public getContext() : CanvasRenderingContext2D {
    if (this.context instanceof CanvasRenderingContext2D) {
      return this.context;
    }
    throw new Error(`Not backed by a canvas`);
  }

  public getPath() : Path2D {
    if (!(this.context instanceof Path2D)) {
      throw new Error('Unable to retrieve path from CanvasRenderer; backing context is not Path2D');
    }

    return this.context;
  }
  public lineTo(x: number , y: number) {
    this.context.lineTo(x,y);
  }

  public moveTo(x: number , y: number) {
    this.context.moveTo(x,y);
  }

  public restore() {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.restore();
    }
  }

  public save() {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.save();
    }
  }
  public stroke(path?: Path2D) {
    if (this.context instanceof Path2D) {
      throw new Error('Path2D cannot use stroke');
    }
    if (path) {
      this.context.stroke(path);
    } else {
      this.context.stroke();
    }
  }

  public strokeRect(x: number, y: number, w: number, h: number) {
    if (this.context instanceof CanvasRenderingContext2D) {
      this.context.strokeRect(x,y,w,h);
    }
  }

  private getProp(prop: string) : any {
    if (this.context instanceof CanvasRenderingContext2D) {
      // force typescript to let us index context
      const ctx : unknown = this.context;

      return (ctx as IndexableRO<string|number>)[prop];
    }

    return '';
  }

  private setProp(prop: string, value: any) {
    if (this.context instanceof CanvasRenderingContext2D) {
      // force typescript to let us index context
      const ctx : unknown = this.context;
      (ctx as Indexable<string|number>)[prop] = value;
    }
  }
}
