/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import { Scatterer } from '@/perturbers';
import { AppCommand } from './appCommand';
import { PerturbCommand } from './perturbCommand';

export class ScatterCommand extends AppCommand {

  constructor() {
    super();
  }

  public run(app: ReactJigsaw) {
    app.startPhysics();
    app.exec(new PerturbCommand(new Scatterer()));
  }
}
