/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Dim, Rect, Side } from '@/geom';
import * as logger from '@/logger';
import { TongueType } from '@/tongue';

import type { TongueParams } from '@/tongue';

type CalcDimensionsResult = { screenDim: Dim; sourceDim: Dim };

type TongueMapEntry = boolean|null;
type TongueMap = TongueType[][][];

export type Cutout = {
  column: number;
  row: number;
  screenRect: Rect;
  sourceRect: Rect;
  tongues: TongueType[];
};

export class PieceCutter {
  private imageDim: Dim = new Dim();
  private jigsawDim: Dim = new Dim();

  constructor(private columns = 0, private rows = 0) {
  }

  public *cut(imageDim: Readonly<Dim> = new Dim(), jigsawDim: Readonly<Dim> = new Dim())
  : Generator<Cutout, void, void> {

    this.imageDim.set(imageDim);
    this.jigsawDim.set(jigsawDim);
    const { rows, columns } = this;

    logger.debug('PieceCutter image dim', imageDim);

    const { screenDim, sourceDim } = this.calcDimensions();
    logger.debug('PieceCutter dim (screen,source)', screenDim, sourceDim);
    const tongueMap : TongueMap = this.initTongueMap();

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < columns; j++) {
        const tongues : TongueType[] = tongueMap[i][j];

        yield {
          column: j,
          row: i,
          sourceRect: Rect.fromXYWidthHeight(
            Math.floor(j * sourceDim.w),
            Math.floor(i * sourceDim.h),
            Math.ceil(sourceDim.w),
            Math.ceil(sourceDim.h),
          ),
          screenRect: Rect.fromXYWidthHeight(
            Math.floor(j * screenDim.w),
            Math.floor(i * screenDim.h),
            Math.ceil(screenDim.w),
            Math.ceil(screenDim.h),
          ),
          tongues,
        };
      }
    }
  }

  private initTongueMap() : TongueMap {
    const tongues : TongueMap = [];
    const { rows, columns } = this;
    const isPredictable = false; // @todo disable this

    for (let i = 0; i < rows; i++) {
      tongues[i] = tongues[i] || [];

      for (let j = 0; j < columns; j++) {
        tongues[i][j] = tongues[i][j] || Array(4).fill(TongueType.NONE);

        if (i < rows - 1) {
          tongues[i][j][Side.BOTTOM] = isPredictable ? TongueType.OUT : (Math.random() > 0.5 ? TongueType.OUT : TongueType.IN);
        }
        if (j < columns - 1) {
          tongues[i][j][Side.RIGHT] = isPredictable ? TongueType.IN : (Math.random() > 0.5 ? TongueType.OUT : TongueType.IN);
        }
        if (i > 0) {
          tongues[i][j][Side.TOP] = tongues[i-1][j][Side.BOTTOM] === TongueType.OUT ? TongueType.IN : TongueType.OUT;
        }
        if (j > 0) {
          tongues[i][j][Side.LEFT] = tongues[i][j-1][Side.RIGHT] === TongueType.OUT ? TongueType.IN : TongueType.OUT;
        }
      }
    }

    return tongues;
  }

  private calcDimensions() : CalcDimensionsResult {
    const { rows, columns, imageDim, jigsawDim } = this;
    // screen piece sizes
    const screenDim = new Dim(
      Math.floor(jigsawDim.width / columns),
      Math.floor(jigsawDim.height / rows),
    );

    logger.debug('image dimensions for cutter', imageDim?.width, imageDim?.height);

    const sourceDim = new Dim(
      imageDim ? Math.floor(imageDim.width / columns) : 0,
      imageDim ? Math.floor(imageDim.height / rows) : 0,
    );

    return {
      screenDim,
      sourceDim,
    };
  }
}
