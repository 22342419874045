/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { DefaultDotRadius } from '@/constants';

export function $$(sel: string, doc: HTMLDocument|HTMLElement = document) {
  return Array.from(doc.querySelectorAll(sel));
}

export function $(sel: string, doc: HTMLDocument|HTMLElement = document) {
  return doc.querySelector(sel);
}

export function *range(max: number, min: number = 0) {
  for (let i = min; i < max; i++) {
    yield i;
  }
}

export function randRange(min: number, max: number) {
  return Math.random() * (max-min) + min;
}

export function randRangeFloor(min: number, max: number) {
  return Math.floor(Math.random() * (max-min) + min);
}

export function randomElement(array: any[]) {
  return array[randRangeFloor(0, array.length)];
}

export function cursor(name: string, element?: HTMLElement) {
  (element || document.documentElement).style.cursor = name || 'pointer';
}

export function dot(x: number, y: number, color = [255, 0, 0],
  { context, radius = DefaultDotRadius }: { context?: CanvasRenderingContext2D; radius?: number} = {}) : void
{
  if (!context) {
    throw new Error(`No context provided to 'dot'`);
  }
  context.beginPath();
  context.ellipse(x, y, radius/2, radius/2, 0, 0, Math.PI * 2);
  context.fillStyle = color instanceof Array ? `rgba( ${color.join(',')} )` : color;
  context.fill();
}

export function createCanvas(w: number, h: number) {
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;

  return canvas;
}

export function canvasToImage(canvas: HTMLCanvasElement) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const dataURL = canvas.toDataURL();
    image.onload = () => resolve(image);
    image.onerror = e => reject(new Error(`Error in canvasToImage: ${dataURL}`));
    image.src = dataURL;
  });
}

export function absolutePath(relativePath: string) : string {
  const { origin, pathname } = document.location;

  return `${origin}${pathname}${relativePath}`;
}
