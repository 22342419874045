/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Immutable } from '@/jtypes';
import type { Line, PointInterface, XY } from './types';

import { Point } from './point';

export type IntersectResult = {
  d: number; // determinant
  t: number; // 't' in parametric equation of line
  p: Point;  // intersection point
};

export class Vec extends Point {

  public static create(xOrPoint: number|Readonly<XY> = 0, y: number = 0) : Point {
    return new Vec(xOrPoint, y);
  }

  public static fromTwoPoints(p1: PointInterface, p2: PointInterface) : Vec {
    return new Vec(p2).sub(p1);
  }

  // from Paul Bourke -  http://astronomy.swin.edu.au/~pbourke/geometry/lineline2d/
  public static intersect(line1: Line, line2: Line, result?: Partial<IntersectResult>) : boolean {
    // p1 : Point, a : Vec, p3: Point, b : Vec) {
  // public GPointI  intersect(GPointI p1, GPointI p2, GPointI p3, GPointI p4)
    // gVector a = new GVector(p1,p2);
    // gVector b = new GVector(p3,p4);
    const d = (line2.v.y * line1.v.x - line2.v.x * line1.v.y); // perp dot product
    if( d === 0 ) {
      // parallel lines
      return false;
    }
    const t = (
      line2.v.x * (line1.p.y - line2.p.y)
      - line2.v.y * (line1.p.x - line2.p.x)
      )  / d;

    if (result) {
      if (result.p) {
        result.p.set(
          line1.p.x + t * line1.v.x,
          line1.p.y + t * line1.v.y,
        );
      }
      result.t = t;
      result.d = d;
    }

    return true;
  }

  public static limitLength(v: Vec, maxLen: number) : Vec {
    return v.clone().limitLength(maxLen);
  }

  public static normalize(v: Vec) : Vec {
    return v.clone().normalize();
  }

  public static projOnto(v1: Immutable<Vec>, vOnto: Immutable<Vec>) : Vec {
    const dotRatio = v1.dot(vOnto) / vOnto.dot(vOnto);

    // using proj v on w = [(v.w)/(w.w)] * w
    return vOnto.times( dotRatio );
  }

  public static rotate90CCW(v: Vec) : Vec {
    return v.clone().rotate90CCW();
  }

  public static rotate90CW(v: Vec) : Vec {
    return v.clone().rotate90CW();
  }

  public static setLength(v: Vec, len: number) : Vec {
    return v.clone().setLength(len);
  }


  constructor(xOrPoint: number|Readonly<XY> = 0, y: number = 0) {
    super(xOrPoint, y);
  }

  get length() {
    return Math.sqrt(this.sqlen);
  }

  get sqlen() {
    return this.x * this.x +
      this.y * this.y;
  }

  public angle() {
    return Math.atan2(this.y, this.x);
  }

  public clone() : this {
    return new Vec(this) as this;
  }

  public dot(v: Immutable<Vec>) : number {
    return this.x * v.x + this.y * v.y;
  }

  public limitLength(maxLen: number) : this {
    const sqlen = this.sqlen;
    // near-0 len
    if (Math.abs(sqlen) < Number.EPSILON) {
      return this;
    }

    const maxLenSq = maxLen * maxLen;
    if (sqlen > maxLenSq) {
      const len = Math.sqrt(sqlen);
      this.x = this.x * maxLen / len;
      this.y = this.y * maxLen / len;
    }

    return this;
  }

  public normalize() : this {
    const len = this.length;
    if (len === 0) {
      return this;
    }
    this.x /= len;
    this.y /= len;

    return this;
  }

  public rotate90CCW() : this {
    const x = this.x;
    this.x = this.y;
    this.y = -x;

    return this;
  }

  public rotate90CW() : this {
    const x = this.x;
    this.x = -this.y;
    this.y = x;

    return this;
  }

  public setLength(newLen: number) : this {
    const len = this.length;
    if (len === 0) {
      this.x = newLen;
      this.y = 0;

      return this;
    }
    this.x = this.x * newLen / len;
    this.y = this.y * newLen / len;

    return this;
  }

}
