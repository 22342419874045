/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect } from '@/geom';
import type { Indexable, IndexableRO } from '@/jtypes';
import * as logger from '@/logger';

interface EventListener {
  onKeyDown?(event: EventData) : void;
  onKeyUp?(event: EventData) : void;
  onMouseDown?(event: EventData) : void;
  onMouseDragged?(event: EventData) : void;
  onMouseMoved?(event: EventData) : void;
  onMouseUp?(event: EventData) : void;
}

type Modifiers = {
  Alt: boolean;
  Control: boolean;
  OS: boolean;
  Shift: boolean;
} & Indexable<boolean>;

export interface EventData {
  readonly isMouseDown: boolean;
  readonly isWithinBounds: boolean;
  readonly key: string;
  readonly lastMouse: Readonly<Point>;
  readonly mouseDelta: Readonly<Point>;
  readonly mousePos: Readonly<Point>;
  readonly pressPos: Readonly<Point>;
  readonly rawEvent: Event;
  readonly modifiers: Modifiers;
  isKeyDown(key: string) : boolean;
}

type Shortcut = {
  keySeq: string[];
  keySeqDesc: string;
  modifiers: string[];
  callback(keySeq: string) : void;
};

export class BaseEventHandler {
  public isWithinBounds = false;
  public isMouseDown = false;
  public key: string = '';
  public lastMouse = new Point();
  public mouseDelta = new Point();
  public mousePos = new Point();
  public pressPos = new Point();
  public modifiers: Modifiers = { Alt: false, Control: false, OS: false, Shift: false };

  public isKeyDown(key: string) : boolean {
    return false;
  }


  public registerShortcut(requestedKeySeq: string, callback: (keySeq: string) => void) : void {
    /* NOOP */
  }
}

export class EventHandler extends BaseEventHandler {
  public rawEvent: Event = new Event('invalid');
  public modifiers: Modifiers = { Alt: false, Control: false, OS: false, Shift: false };

  private keyMap: Map<string, boolean> = new Map();
  private shortcuts: Shortcut[] = [];
  private touchEventID: number = 0;

  private rootBounds = new Rect();
  /**
   * Create event handler
   *
   * @param  {HTMLElement} root     [description]
   * @param  {Function} listener [description]
   */
  constructor(private root: HTMLElement, private listener: EventListener) {
    super();
    this.getElementBounds(this.root, this.rootBounds);
    this.bindEvents();
  }

  public isKeyDown(key: string) {
    return this.keyMap.get(key) || false;
  }

  public registerShortcut(requestedKeySeq: string, callback: (keySeq: string) => void) {
    const { keySeq, modifiers } = this.normalizeKeySeq(requestedKeySeq);
    let keySeqDesc = modifiers.join('+');
    keySeqDesc = keySeqDesc ? [keySeqDesc, keySeq.join(',')].join(',') : keySeq.join(',');

    const shortcut = {
      keySeq,
      keySeqDesc,
      modifiers,
      callback,
    };
    if (!this.shortcuts.find(s => (s.keySeqDesc === shortcut.keySeqDesc && s.callback === callback))) {
      this.shortcuts.push(shortcut);
    }
  }

  public setReferenceElement(elt: HTMLElement) {
    this.root = elt;
    this.getElementBounds(this.root, this.rootBounds);
  }

  public setListener(listener: EventListener) {
    this.listener = listener;
  }

  private bindEvents() {
    document.addEventListener('visibilitychange', () => {
      this.keyMap.clear();
    });
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);

    document.addEventListener('mousedown', this.onMouseDown);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this.onMouseMove);

    document.addEventListener('touchstart', e => this.convertTouchToMouseEvent(e));
    document.addEventListener('touchend', e => this.convertTouchToMouseEvent(e));
    document.addEventListener('touchmove', e => this.convertTouchToMouseEvent(e));
  }

  private convertTouchToMouseEvent(e: TouchEvent) {
    if (e.target) {
      const targetElt = (e.target as HTMLElement);
      if (targetElt.matches('select,input[type=number],input[type=text],textbox')) {
        return;
      }
    }

    let type = 'mousemove';

    if (e.touches.length > 1) {
      return; // allow default multi-touch gestures to work
    }

    let touch : Touch|null|undefined = e.changedTouches[0];

    switch (e.type) {
      case 'touchstart':
        type = 'mousedown';
        touch = e.touches[0] || e.changedTouches[0];
        this.touchEventID = touch?.identifier;
        break;
      case 'touchend':
        type = 'mouseup';
        touch = [...Array.from(e.changedTouches), ...Array.from(e.touches)].find(t => t.identifier === this.touchEventID);

        if (touch) {
          this.touchEventID = 0;
        }
        break;
      case 'touchmove':
        type = 'mousemove';
        touch = [...Array.from(e.changedTouches), ...Array.from(e.touches)].find(t => t.identifier === this.touchEventID);
        break;
      default:
        throw new Error(`Unknown touch event ${e.type}`);
    }

    if (!touch) {
      return false;
    }
    if (touch!.target !== this.root) {
      return;
    }

    const {
      ctrlKey, shiftKey, altKey, metaKey,
    } = e;
    const {
      screenX, screenY,
      clientX, clientY,
      target,
    } = touch as Touch;

    // https://developer.mozilla.org/en/DOM/event.initMouseEvent for API
    const mouseEvent = new MouseEvent(type, {
      screenX, screenY,
      clientX, clientY,
      ctrlKey, shiftKey, altKey, metaKey,
      buttons: 1, button: 0,
      bubbles: true,
    });

    if (e.cancelable) {
      e.preventDefault();
    }

    e.stopPropagation();

    logger.debug('converted', touch, '🡆', mouseEvent);
    target?.dispatchEvent(mouseEvent);

    return false;
  }

  private dispatchShortcut(key: string, modifiers: Readonly<Modifiers>) {
    for (const shortcut of this.shortcuts) {
      if (shortcut.keySeq.indexOf(key) < 0) {
        continue;
      }
      if (!shortcut.modifiers.reduce((allPressed, mod) => modifiers[mod] && allPressed, true)) {
        continue;
      }
      for (const sKey of shortcut.keySeq) {
        if (sKey === key) {
          continue;
        }
        if (!this.keyMap.get(sKey)) {
          return false;
        }
      }
      logger.debug('checking final key ', key, shortcut);
      if (this.keyMap.get(key)) {
        logger.debug('dispatching ', shortcut.keySeqDesc);
        shortcut.callback(shortcut.keySeq.join('+'));
      }
    }

    return false;
  }

  private normalizeKeySeq(keySeq: string) : { keySeq: string[]; modifiers: string[] } {
    const lookup : IndexableRO<string> = {
      'shift': 'Shift',
      'ctrl': 'Control',
      'control': 'Control',
      'alt': 'Alt',
      'win': 'OS',
      'windows': 'OS',
      'command': 'OS',
      'altgr': 'AltGraph',
      'alt-gr': 'AltGraph',
    };

    const outSeq : string[] = [];
    const modifiers : string[] = [];

    keySeq.split(/\s+|\+|-/).forEach(k => {
      const key = lookup[k]||k;
      if (key.match(/Shift|Alt|Control|OS/)) {
        modifiers.push(key);
      } else {
        outSeq.push(key);
      }
    });

    modifiers.sort();

    return { keySeq: outSeq, modifiers };
  }

  private getElementBounds(element: HTMLElement, r = new Rect()) {
    let e : HTMLElement|null = element;
    r.set(0, 0, e.offsetWidth, e.offsetHeight);

    while(e) {
      r.pos.x += e.offsetLeft;
      r.pos.y += e.offsetTop;
      e = e.offsetParent as HTMLElement;
    }

    return r;
  }

  private updatePointForRefElement(point: Point) {
    return point.sub(this.rootBounds.topLeft);
  }

  private updateMousePos(e: MouseEvent) {
    this.lastMouse = this.lastMouse ? this.lastMouse.set(this.mousePos) : this.mousePos.clone();
    this.mousePos.set(e.clientX, e.clientY);
    this.updatePointForRefElement(this.mousePos);
    this.mouseDelta.set(this.mousePos).sub(this.lastMouse);
    this.isWithinBounds = this.rootBounds.containsPoint(this.mousePos);
  }

  private updateModifiers(e: MouseEvent|KeyboardEvent) {
    this.modifiers.Shift = e.shiftKey;
    this.modifiers.Control = e.ctrlKey;
    this.modifiers.Alt = e.altKey;
    this.modifiers.OS = e.metaKey;
  }

  private onKeyDown = (e: KeyboardEvent) => {
    const { key } = e;
    // logger.debug('keydown', key, e);
    this.rawEvent = e;
    this.updateModifiers(e);

    if (e.target) {
      const targetElt = (e.target as HTMLElement);
      if (targetElt.matches('select,input[type=number],input[type=text],textbox')) {
        return;
      }
    }

    this.keyMap.set(key, true);
    this.key = key;

    const modifiers = {
      Shift: e.shiftKey,
      Control: e.ctrlKey,
      Alt: e.altKey,
      OS: e.metaKey,
    };

    if (this.dispatchShortcut(key, modifiers)) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }
    if (this.listener.onKeyDown) {
      this.listener.onKeyDown(this as EventData);
    }
  }

  private onKeyUp = (e: KeyboardEvent) => {
    const { key } = e;
    this.updateModifiers(e);
    this.rawEvent = e;
    // logger.debug('keyup', key);
    this.keyMap.set(key, false);
  }

  private onMouseDown = (e: MouseEvent) => {
    this.rawEvent = e;
    this.updateModifiers(e);
    this.updateMousePos(e);
    this.pressPos.set(this.mousePos);
    logger.debug('mousedown', this.mousePos);

    if (e.target) {
      if ((e.target as HTMLElement).tagName?.match(/button|input|textarea|select/i)) {
        return;
      }
    }

    this.isMouseDown = true;

    if (this.listener.onMouseDown) {
      this.listener.onMouseDown(this as EventData);
    }
  }

  private onMouseMove = (e: MouseEvent) => {
    this.rawEvent = e;
    this.updateModifiers(e);
    this.updateMousePos(e);

    if (this.isMouseDown) {
      if (this.listener.onMouseDragged) {
        this.listener.onMouseDragged(this as EventData);
      }
    } else {
      if (this.listener.onMouseMoved) {
        this.listener.onMouseMoved(this as EventData);
      }
    }
  }

  private onMouseUp = (e: MouseEvent) => {
    this.rawEvent = e;
    this.updateModifiers(e);
    this.updateMousePos(e);
    this.isMouseDown = false;

    if (this.listener.onMouseUp) {
      this.listener.onMouseUp(this as EventData);
    }
  }
}
