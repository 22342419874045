/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import { JigsawCommand } from './jigsawCommand';


export class MovePieceCommand extends JigsawCommand {
  constructor(private piece: Piece, private dx: number, private dy: number) {
    super();
  }

  public run(jigsaw: IJigsaw) {
    jigsaw.movePieceBy(this.piece, this.dx, this.dy);
  }
}
