/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { HasHelp } from '@/components/HasHelp';
import * as React from 'react';

import styles from '@/styles/previewimage.scss';

type ImageSourceType = 'hosted' | 'uploaded' | 'api' | 'external';

type Props = {
  title?: string;
  link?: string;
  type?: ImageSourceType;
  origin?: string;
  src?: string;
  onNewImage?() : void;
};

export function PreviewImage(props: Props) {
  const { title, link, src, onNewImage } = props;

  const alt = title || 'Current jigsaw image';

  const Link = link
    ? (props: { children?: React.ReactNode}) => (<a target='_blank' rel='noopener nofollow noreferrer' href={link}>{props.children}</a>)
    : (props: { children?: React.ReactNode}) => (<>{props.children}</>);

  return (
    <figure className={styles.previewContainer}>
      {src &&
        <Link>
          <img
            title={title && `See original picture`}
            className={styles.preview}
            alt={alt}
            src={src}
          />
        </Link>
      }
      <div className={styles.newImage}>
        <HasHelp>
          <button onClick={onNewImage}
            title='Pick a new image' className={`${styles.newImageButton} icon`}
          >refresh</button>
        </HasHelp>
      </div>
      {title &&
        <figcaption>
          <Link>{title}</Link>
        </figcaption>
      }
    </figure>
  );
}
