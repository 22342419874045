/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */


export async function promiseFirst(promises: (Promise<any>|Function)[]) {
  let lastError = null;

  for (const p of promises) {
    try {
      const result = p instanceof Promise ? await p : await p();

      return result;
    } catch (e) {
      lastError = e;
    }
  }

  throw lastError;
}
