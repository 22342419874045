/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ICommand } from '@/commands';
import type { ReactJigsaw } from '@/components/ReactJigsaw';

import { FullscreenCommand, HintCommand, ScatterCommand, ToggleEdgesCommand } from '@/commands';
import { CommandButton } from '@/components/CommandButton';
import { HasHelp } from '@/components/HasHelp';
import classNames from '@/helpers/classNames';
import * as logger from '@/logger';
import * as React from 'react';

import styles from '@/styles/topbuttons.scss';

type State = {
  isToggled: boolean;
};

type Props = {
  app: ReactJigsaw;
  className?: string;
  enabled?: boolean;
  showHelp?: boolean;
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
  isShowingOnlyEdges?: boolean;
  isSidebarHidden?: boolean;
  children?: React.ReactNode;
  onClick?() : void;
  onSidebarToggleClick?() : void;
};

export class TopButtons extends React.Component<Props,State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  public render() {
    const {
      onSidebarToggleClick,
      isSidebarHidden,
      enabled,
      tooltipSide = 'left',
      isShowingOnlyEdges,
      className: addClass = '',
      showHelp = false,
      children = [],
    } = this.props;

    const execCommand = <T,>(cmd?: ICommand<T>) => {
      if (cmd) {
        this.props.app.exec(cmd);

        if (this.props.onClick) {
          this.props.onClick();
        }
      }
    };

    return (
      <div className={`top-buttons ${styles.topButtons} ${addClass}`}>

        {children}

        <CommandButton
          title='View fullscreen'
          shortcut='f'
          icon='fullscreen'
          tooltipSide={tooltipSide}
          showHelp={showHelp}
          onClick={execCommand}
          className='command--fullscreen'
          command={new FullscreenCommand()}
        />

        <span className={`topbuttons__divider ${styles.divider}`} />

        <CommandButton
          title='Only show edge pieces'
          shortcut='e'
          icon={isShowingOnlyEdges ? 'onlyedges' : 'allpieces'}
          tooltipSide={tooltipSide}
          showHelp={showHelp}
          onClick={execCommand}
          command={new ToggleEdgesCommand(ToggleEdgesCommand.Mode.Toggle)}
        />

        <CommandButton
          title='Scatter pieces'
          shortcut='s'
          icon='blow'
          tooltipSide={tooltipSide}
          showHelp={showHelp}
          onClick={execCommand}
          command={new ScatterCommand()}
        />

        <CommandButton
          title='Get a hint'
          shortcut='h'
          icon='hint'
          className='command--hint'
          tooltipSide={tooltipSide}
          showHelp={showHelp}
          onClick={execCommand}
          command={new HintCommand()}
        />
      </div>);
  }
}
