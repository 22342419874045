/* A polyfill for browsers that don't support ligatures. */
/* The script tag referring to this file must be placed before the ending body tag. */

/* To provide support for elements dynamically added, this script adds
   method 'icomoonLiga' to the window object. You can pass element references to this method.
*/
(function () {
    'use strict';
    function supportsProperty(p) {
        var prefixes = ['Webkit', 'Moz', 'O', 'ms'],
            i,
            div = document.createElement('div'),
            ret = p in div.style;
        if (!ret) {
            p = p.charAt(0).toUpperCase() + p.substr(1);
            for (i = 0; i < prefixes.length; i += 1) {
                ret = prefixes[i] + p in div.style;
                if (ret) {
                    break;
                }
            }
        }
        return ret;
    }
    var icons;
    if (!supportsProperty('fontFeatureSettings')) {
        icons = {
            'onlyedges': '&#xe922;',
            'anchor': '&#xe914;',
            'tick': '&#xe923;',
            'tickcircled': '&#xe924;',
            'down': '&#xe925;',
            'left': '&#xe915;',
            'right': '&#xe916;',
            'left2': '&#xe917;',
            'right2': '&#xe918;',
            'cloud': '&#xe900;',
            'crosshair': '&#xe901;',
            'externallink': '&#xe90c;',
            'visible': '&#xe902;',
            'hidden': '&#xe903;',
            'gift': '&#xe919;',
            'globe': '&#xe904;',
            'help': '&#xe91a;',
            'hint': '&#xe91b;',
            'layout': '&#xe921;',
            'link': '&#xe905;',
            'linkflat': '&#xe906;',
            'click': '&#xe907;',
            'fullscreen': '&#xe90e;',
            'menu': '&#xe908;',
            'restore': '&#xe90f;',
            'fullscreen-monitor': '&#xe910;',
            'radio': '&#xe91c;',
            'refresh': '&#xe920;',
            'settings': '&#xe909;',
            'upload': '&#xe90a;',
            'share': '&#xe90b;',
            'back': '&#xe91d;',
            'forward': '&#xe91e;',
            'allpieces': '&#xe913;',
            'off': '&#xe92c;',
            'on': '&#xe92d;',
            'tool': '&#xe911;',
            'uploadtocloud': '&#xe90d;',
            'soundson': '&#xe926;',
            'mute': '&#xe927;',
            'blow': '&#xe912;',
            'close': '&#xe928;',
            'circledx': '&#xe929;',
            'zap': '&#xe91f;',
            'zoomin': '&#xe92a;',
            'zoomout': '&#xe92b;',
          '0': 0
        };
        delete icons['0'];
        window.icomoonLiga = function (els) {
            var classes,
                el,
                i,
                innerHTML,
                key;
            els = els || document.getElementsByTagName('*');
            if (!els.length) {
                els = [els];
            }
            for (i = 0; ; i += 1) {
                el = els[i];
                if (!el) {
                    break;
                }
                classes = el.className;
                if (/icon/.test(classes)) {
                    innerHTML = el.innerHTML;
                    if (innerHTML && innerHTML.length > 1) {
                        for (key in icons) {
                            if (icons.hasOwnProperty(key)) {
                                innerHTML = innerHTML.replace(new RegExp(key, 'g'), icons[key]);
                            }
                        }
                        el.innerHTML = innerHTML;
                    }
                }
            }
        };
        window.icomoonLiga();
    }
}());
