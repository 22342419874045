/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Rect } from '@/geom';

import type { Point } from '@/geom';
import type { IJigsaw } from '@/jigsaw';
import type { Piece } from './piece';

export class PiecePosition {
  private bounds = new Rect();

  constructor(public piece: Piece, private jigsaw: IJigsaw) {
    this.bounds.set(piece.getBoundingRect());
  }

  public getPos() { return this.piece.getPos().plus(this.bounds.dimensions.scale(0.5)); }

  public setPos(pos: Readonly<Point>) {
    this.jigsaw.movePieceTo(this.piece, pos.x - this.bounds.width/2, pos.y - this.bounds.height/2);
  }
}
