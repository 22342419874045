/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

export const imageList = [
  'images/1914.1018 - Love of Winter.jpg',
  'images/1922.4472 - Fishing Boats with Hucksters Bargaining for Fish.jpg',
  'images/1925.3245 - Under the Wave off Kanagawa (Kanagawa oki nami....jpg',
  'images/1933.1235 - After the Hurricane, Bahamas.jpg',
  'images/1964.336 - Paris Street; Rainy Day.jpg',
  'images/DP124093.jpg',
  'images/DP-14286-009.jpg',
  'images/DP164867.jpg',
  'images/DP231550.jpg',
  'images/DT11343.jpg',
  'images/DT1859.jpg',
  'images/DT1906.jpg',
  'images/DT1967.jpg',
  'images/DT200613.jpg',
  'images/DT45.jpg',
  'images/DT46.jpg',
  'images/DT47.jpg',
  'images/DT50.jpg',
];
