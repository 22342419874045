/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { IJigsaw } from '@/jigsaw';
import { BaseCommand } from './baseCommand';
import type { IJigsawCommand } from './types';

export abstract class JigsawCommand extends BaseCommand implements IJigsawCommand {
  constructor() {
    super();
  }

  public abstract run(jigsaw: IJigsaw) : void;
}
