/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { EventEmitterCallback, IEventEmitter } from './types';

export class NullEventEmitter implements IEventEmitter {
  public on(eventName: string, cb: EventEmitterCallback) : void { /* NOOP */ }
  public oncePromised(eventName: string) : Promise<any> { return Promise.reject(); }
  public once(eventName: string, cb?: EventEmitterCallback) : Promise<any> { return Promise.reject(); }
  public off(eventName: string, cb: EventEmitterCallback) : void { /* NOOP */ }
  public emit(eventName: string, data?: any) : boolean { return false; }
}
