/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Point } from '@/geom/point';

export abstract class AbstractRenderer {
  abstract get fillStyle() : string | CanvasGradient | CanvasPattern;
  abstract set fillStyle(style: string | CanvasGradient | CanvasPattern);
  abstract get font() : string;
  abstract set font(style: string);
  abstract get lineWidth() : number;
  abstract set lineWidth(style: number);
  abstract get strokeStyle() : string | CanvasGradient | CanvasPattern;
  abstract set strokeStyle(style: string | CanvasGradient | CanvasPattern);
  abstract get textAlign() : string;
  abstract set textAlign(style: string);
  public abstract beginPath() : void;
  public abstract bezierCurveTo(cp1: Point, cp2: Point, p: Point) : void;
  public abstract clearRect(x: number, y: number, w: number, h: number) : void;
  public abstract closePath() : void;
  public abstract ellipse(x: number, y: number, w: number, h: number, th: number, start: number, end: number) : void;
  public abstract fill(color?: string|object) : void;
  public abstract fillRect(x: number, y: number, w: number, h: number) : void;
  public abstract fillText(text: string, x: number, y: number) : void;
  public abstract lineTo(x: number , y: number) : void;
  public abstract moveTo(x: number , y: number) : void;
  public abstract restore() : void;
  public abstract save() : void;
  public abstract stroke(color?: string|object) : void;
  public abstract strokeRect(x: number, y: number, w: number, h: number) : void;
}
