/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { WxH, XY } from './types';

import { Point } from './point';
import { Rect } from './rect';


export class Dim extends Point {

  constructor(wOrDim?: number|Readonly<XY>|Readonly<WxH>, h?: number) {
    if (typeof(wOrDim) === 'object') {
      if ((wOrDim as WxH).width) {
        super((wOrDim as WxH).width, (wOrDim as WxH).height);
      } else if ((wOrDim as XY).x) {
        super((wOrDim as XY).x, (wOrDim as XY).y);
      }
    } else {
      super(wOrDim, h);
    }
  }

  get aspectRatio() {
    return Math.max(this.width, this.height) / Math.min(this.width, this.height);
  }
  get h() { return this.height; }
  set h(y) { this.y = y; }
  get height() { return this.y; }
  set height(y) { this.y = y; }

  get isLandscape() { return this.w > this.h; }
  get isPortrait() { return !this.isLandscape; }

  get w() { return this.width; }
  set w(x) { this.x = x; }
  get width() { return this.x; }
  set width(x) { this.x = x; }

  public clone() : this {
    return new Dim(this.w, this.h) as this;
  }

  public toRect() : Rect {
    return Rect.fromDim(this);
  }

  public toString() {
    return `${this.w}×${this.h}`;
  }
}
