/*!

 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Immutable } from '@/jtypes';
import { Dim, Line, Point, PointRO, RectRO, Side, Vec, XY } from './index';

type HasXY = { x: number; y: number };
type HasPos = { pos: Readonly<Point> };
type HasWidthHeight = { width: number; height: number };
type HasWH = { w: number; h: number };

type RectInit = (HasPos & HasWidthHeight) | (HasPos & HasWH) | (HasXY & HasWH)
  | (HasXY & HasWidthHeight) | (HasWH) | (HasWidthHeight);

import type { IntersectResult } from './vec';

type RectIntersectCenterResult = Partial<IntersectResult> & {
  side?: Line;
};

export class Rect {

  public static getEdgeDistanceVector(r1: RectRO, r2: RectRO, vOut: Vec = new Vec()) {
    const result = { d: 0, t: 0, p: new Point() };

    vOut.set(r2.center).sub(r1.center);

    if (!r1.getIntersectFromCenter(vOut, result)) {
      throw new Error(`No intersect from ${r1.center} ↗ ${vOut}, R ${r1}`);
    }

    const p0 = result.p.clone();

    if (!r2.getIntersectFromCenter(vOut, result)) {
      throw new Error(`No intersect from ${r2.center} ↗ ${vOut}, R: ${r2}`);
    }

    vOut.set(result.p).sub(p0);
  }

  public static merge(r1: Immutable<Rect>, r2: Immutable<Rect>, result?: Rect) : Rect {
    if (!result) {
      result = new Rect();
    }

    if (r1.isEmpty()) {
      result.set(r2);

      return result;
    }

    const x = Math.min(r2.pos.x, r1.pos.x);
    const y = Math.min(r2.pos.y, r1.pos.y);

    return result.set(
      x, y,
      Math.max(r2.x + r2.width, r1.x + r1.width) - x,
      Math.max(r2.y + r2.height, r1.y + r1.height) - y,
    );
  }

  public static split(extract: Immutable<Rect>, from: Immutable<Rect>) : Rect[] {
    const out = [];

    if (!from.hasIntersect(extract)) {
      return [from.clone()];
    }

    if (from.isEmpty()) {
      return [];
    }

    if (extract.isEmpty()) {
      return [from.clone()];
    }

    const inter = extract.intersect(from);
    let r;

    r = Rect.fromTopLeftBottomRight(
      from.topLeft,
      new Point(from.bottomRight.x, inter.topLeft.y),
    );

    if (!r.isEmpty()) {
      out.push(r);
    }

    r = Rect.fromTopLeftBottomRight(
      new Point(from.topLeft.x, inter.topLeft.y),
      new Point(inter.topLeft.x, inter.bottomRight.y),
    );

    if (!r.isEmpty()) {
      out.push(r);
    }

    r = Rect.fromTopLeftBottomRight(
      new Point(inter.bottomRight.x, inter.topLeft.y),
      new Point(from.bottomRight.x, inter.bottomRight.y),
    );

    if (!r.isEmpty()) {
      out.push(r);
    }

    r = Rect.fromTopLeftBottomRight(
      new Point(from.topLeft.x, inter.bottomRight.y),
      from.bottomRight,
    );

    if (!r.isEmpty()) {
      out.push(r);
    }

    return out;
  }

  public static fromWidthHeight(w: number, h: number) : Rect {
    return Rect.fromXYWidthHeight(0, 0, w, h);
  }

  public static fromDim(d: Dim) : Rect {
    return Rect.fromXYWidthHeight(0, 0, d.w, d.h);
  }

  public static fromPointWidthHeight(pos: Readonly<Point>, width: number, height: number) : Rect  {
    return new Rect(pos.x, pos.y, width, height);
  }

  public static fromXYWidthHeight(x: number, y: number, width: number, height: number) : Rect  {
    return new Rect(x, y, width, height);
  }

  public static fromTopLeftBottomRight(topLeft: Readonly<Point>, br: Readonly<Point>) : Rect {
    const width  = Math.abs(br.x - topLeft.x);
    const height = Math.abs(br.y - topLeft.y);

    return new Rect(topLeft.x, topLeft.y, width, height);
  }

  public static from4PointArray(pts: Readonly<Point>[]) : Rect  {
    return Rect.fromTopLeftBottomRight(pts[0], pts[2]);
  }

  public static fromString(str: string) {
    const match = str.match(/(\d+),(\d+),(\d+)[x,](\d+)/);
    if (!match) {
      throw new Error(`Unable to parse rect from string '${str}`);
    }
    const [, x, y, w, h] = match.map(capture => Number(capture));

    return Rect.fromXYWidthHeight(x,y,w,h);
  }

  public static fromPointCloud(pts: Readonly<Point>[]) : Rect  {
    if (!pts.length) {
      throw new Error(`Empty point array given to Rect.fromPointCloud`);
    }
    const topLeft : Point = pts[0].clone();
    const br : Point = pts[0].clone();

    for (const p of pts) {
      topLeft.set(Math.min(topLeft.x, p.x), Math.min(topLeft.y, p.y));
      br.set(Math.max(br.x, p.x), Math.max(br.y, p.y));
    }

    return Rect.fromTopLeftBottomRight(topLeft, br);
  }

  public static intersect(r1: Immutable<Rect>, r2: Immutable<Rect>, target?: Rect) : Rect {
    return r1.intersect(r2, target);
  }

  public pos: Point = new Point();
  public width: number = 0;
  public height: number = 0;

  constructor(x: number = 0, y: number = 0, w: number = 0, h: number = 0) {
    this.set(x,y,w,h);
  }

  get topLeft() : Readonly<Point> {
    return this.pos;
  }

  get bottomRight() : Point {
    return this.pos.plus(this.width, this.height);
  }

  set bottomRight(p: Point) {
    this.width = p.x - this.x;
    this.height = p.y - this.y;
  }

  /* @todo refactor center as functions, to allow for passing in a destination
  /* @point and prevent always new points  */
  get center() : Point {
    return this.pos.plus(this.width / 2, this.height / 2);
  }

  set center(p: Point) {
    this.pos.set(p).sub(this.width/2, this.height/2);
  }

  get x() { return this.pos.x; }
  get y() { return this.pos.y; }

  get w() { return this.width; }
  set w(w) { this.width = w; }
  get h() { return this.height; }
  set h(h) { this.height = h; }

  get dimensions() : Dim {
    return new Dim(this.width, this.height);
  }

  public getIntersectFromCenter(v: Immutable<Vec>, result: RectIntersectCenterResult = {}) : boolean {
    const points = this.toPointArray();
    const vSide = new Vec();
    const center = this.center;

    // @todo can optimize this based on vector direction
    for (let i = 0; i < points.length; i++) {
      const j = (i + 1) % points.length;
      const p = points[i];
      const q = points[j];
      vSide.set(q).sub(p);

      const side = { p, v: vSide };

      if (Vec.intersect({ p: center, v }, side, result)) {
        if (!result) {
          throw new Error(`Should never happen but TypeScript insists it's possible`);
        }
        if (!((result as any).t >= 0 && (result as any).t <= 1)) {
          continue;
        }
        if (result.side) {
          result.side.p && result.side.p.set(side.p);
          result.side.v && result.side.v.set(side.v);
        }

        return true;
      }
    }

    return false;
  }

  public scale(factor: number, origin?: XY) : this {
    if (origin) {
      this.topLeft.sub(origin);
    }

    this.topLeft.scale(factor);
    this.width *= factor;
    this.height *= factor;

    if (origin) {
      this.topLeft.add(origin);
    }

    return this;
  }

  public isEmpty() : boolean {
    return this.w === 0 && this.h === 0;
  }

  public setPos(x: number|Point, y?: number) {
    this.pos.set(x, y);

    return this;
  }

  public setSize(w: number, h?: number) {
    this.width = w;
    this.height = h ?? w;

    return this;
  }

  public set(xOrRect: number|Immutable<Rect>, y?: number, w?: number, h?: number) {
    if (xOrRect instanceof Rect) {
      this.pos.set(xOrRect.pos);
      this.width = xOrRect.w;
      this.height = xOrRect.h;

      return this;
    }
    this.pos.set(xOrRect, y);
    this.width = w ?? 0;
    this.height = h ?? 0;

    return this;
  }

  public clone() : Rect {
    return Rect.fromXYWidthHeight(this.pos.x, this.pos.y, this.width, this.height);
  }

  public *[Symbol.iterator]() : Generator<number, void, void> {
    yield this.pos.x;
    yield this.pos.y;
    yield this.width;
    yield this.height;
  }

  public outset(x: number, y?: number) : this  {
    if (y === undefined) {
      y = x;
    }
    this.pos.x -= x;
    this.pos.y -= y;
    this.width += x*2;
    this.height += y*2;

    return this;
  }

  public offset(x: number, y?: number) : this {
    if (y === undefined) {
      y = x;
    }
    this.pos.add(x, y);

    return this;
  }

  public inset(x: number, y?: number) : this {
    if (y === undefined) {
      y = x;
    }

    return this.outset(x * -1,  y * -1);
  }

  public ceil() : this {
    this.pos.ceil();
    this.width = Math.ceil(this.width);
    this.height = Math.ceil(this.height);

    return this;
  }

  public floor() : this {
    this.pos.floor();
    this.width = Math.floor(this.width);
    this.height = Math.floor(this.height);

    return this;
  }

  public containsPoint(p: PointRO) {
    return p.x >= this.x && p.y >= this.y
    && p.x < (this.x + this.w)
    && p.y < (this.y + this.h);
  }

  public intersect(other: Immutable<Rect>, target?: Rect) : Rect {
    target = target || new Rect();
    target.set(
      Math.max(this.pos.x, other.pos.x),
      Math.max(this.pos.y, other.pos.y),
      0, 0,
    );
    if (other.isEmpty() || this.isEmpty()) {
      return target;
    }
    target.width = Math.min(this.pos.x + this.width, other.pos.x + other.width)
      - target.pos.x;
    target.height = Math.min(this.pos.y + this.height, other.pos.y + other.height)
      - target.pos.y;

    return target; // (inter.w > 0 && inter.h > 0) ? inter : false;
  }

  public hasIntersect(other: Immutable<Rect>) : boolean {
    if (this.isEmpty() || other.isEmpty()) {
      return false;
    }

    return !this.intersect(other, _tempRect).isEmpty();
  }

  public distanceAbove(other: Immutable<Rect>) : number {
    return other.pos.y - (this.pos.y + this.height);
  }

  public distanceBelow(other: Immutable<Rect>) : number {
    return this.pos.y - (other.pos.y + other.height);
  }

  public distanceLeft(other: Immutable<Rect>) : number {
    return other.pos.x - (this.pos.x + this.width);
  }

  public distanceRight(other: Immutable<Rect>) : number {
    return this.pos.x - (other.pos.x + other.width);
  }

  public orthoDistance(other: Immutable<Rect>) : { distance: number; side: Side } {
    const below = Math.abs(this.distanceBelow(other));
    const above = Math.abs(this.distanceAbove(other));
    const left = Math.abs(this.distanceLeft(other));
    const right = Math.abs(this.distanceRight(other));
    const sides = [above, right, below, left];
    let closest = Side.INVALID;
    let minDist = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < sides.length; i++) {
      if (sides[i] < minDist) {
        minDist = sides[i];
        closest = i;
      }
    }

    return {
      distance: minDist,
      side: closest,
    };
  }

  public distanceByMidpoints(other: Readonly<Rect>) : { distanceSq: number; side: Side } {
    const below = Vec.fromTwoPoints(this.midpointForSide(Side.TOP), other.midpointForSide(Side.BOTTOM)).sqlen;
    const above = Vec.fromTwoPoints(this.midpointForSide(Side.BOTTOM), other.midpointForSide(Side.TOP)).sqlen;
    const left = Vec.fromTwoPoints(this.midpointForSide(Side.RIGHT), other.midpointForSide(Side.LEFT)).sqlen;
    const right = Vec.fromTwoPoints(this.midpointForSide(Side.LEFT), other.midpointForSide(Side.RIGHT)).sqlen;
    const sides = [above, right, below, left];
    let closest = Side.INVALID;
    let minDist = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < sides.length; i++) {
      if (sides[i] < minDist) {
        minDist = sides[i];
        closest = i;
      }
    }

    return {
      distanceSq: minDist,
      side: closest,
    };
  }

  public midpointForSide(side: Side) : Point {
    let x : number;
    let y : number;

    switch (side) {
    case Side.TOP:
      x = this.pos.x + this.width / 2;
      y = this.pos.y;
      break;
    case Side.RIGHT:
      x = this.pos.x + this.width;
      y = this.pos.y + this.height / 2;
      break;
    case Side.BOTTOM:
      x = this.pos.x + this.width / 2;
      y = this.pos.y + this.height;
      break;
    case Side.LEFT:
      x = this.pos.x;
      y = this.pos.y + this.height / 2;
      break;
    default:
      throw new Error(`side ${side} is not valid`);
    }

    return new Point(x,y);
  }

  public isPointInside(p: Readonly<Point>) : boolean {
    const br = this.bottomRight;
    const topLeft = this.topLeft;

    return (
      p.x >= topLeft.x &&
      p.y >= topLeft.y &&
      p.x < br.x &&
      p.y < br.y
    );
  }

  public toArray() : number[] {
    return [...this];
  }

  public toPointArray() : Point[] {
    return [
      this.topLeft.clone(),
      new Point(this.x + this.w, this.y),
      this.bottomRight,
      new Point(this.x, this.y + this.h),
    ];
  }

  public toString() : string {
    return `R (${this.pos} ${this.width}×${this.height})`;
  }

  public toPOJS() {
    const { x, y, width, height } = this;

    return {x, y, width, height};
  }
}

// use in a number of places to avoid excess allocations
const _tempRect = new Rect();
