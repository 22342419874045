/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import { AppCommand } from './appCommand';

enum PhysicsCommandOptions {
  STOP,
  START,
}

export class PhysicsCommand extends AppCommand {
  public static START = PhysicsCommandOptions.START;
  public static STOP = PhysicsCommandOptions.STOP;

  constructor(public isStart: PhysicsCommandOptions) {
    super();
  }

  public run(app: ReactJigsaw) {
    if (this.isStart === PhysicsCommandOptions.STOP) {
      app.stopPhysics();
    } else {
      app.startPhysics();
    }
  }
}
