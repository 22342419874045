/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as logger from '@/logger';
import * as React from 'react';


type State = {
  hasError: boolean;
  lastError: Error|null;
};

type Props = {
};

type ErrorInfo = {
  componentStack: any;
};

export class ErrorBoundary extends React.Component<Props, State> {

  public static getDerivedStateFromError(error: Error) {
    // update state so the next render will show the fallback UI.
    return { hasError: true, lastError: error };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, lastError: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // you can also log the error to an error reporting service
    logger.error('Caught error', error, errorInfo);
  }

  public render() {
    const { hasError, lastError } = this.state;

    if (hasError) {
      // you can render any custom fallback UI
      return (
        <div className='error'>
          <h1>Something went wrong.</h1>
          <p>{lastError?.message}</p>
          <pre><code>{JSON.stringify(lastError?.stack)}</code></pre>
        </div>
      );
    }

    return this.props.children;
  }
}
