/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import classNames from '@/helpers/classNames';
import * as React from 'react';

import styles from '@/styles/mobileMenu.scss';

type Props = Partial<{
  open?: boolean;
  children: React.ReactNode;
  onOpen?() : void;
  onClose?() : void;
}>;

type State = {
  isOpen: boolean;
};

export class MobileMenu extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: props.open ?? false,
    };
  }

  public render() {
    const { children } = this.props;
    const { isOpen } = this.state;
    const attr = {};
    const toggleOpen = () => this.setState(state => {
      if (!state.isOpen) {
        this.props.onOpen && this.props.onOpen();
      } else {
        this.props.onClose && this.props.onClose();
      }

      return { isOpen: !state.isOpen };
    });

    return (
      <div className={classNames({
        'mobile-menu': true,
        'mobile-menu--open': isOpen,
        [styles.mobileMenuOpen]: isOpen,
        [styles.mobileMenu]: true,
       })}>
        <div className={`mobile-menu__inner ${styles.inner}`}>
          {children}
        </div>
        <button
          className={styles.actionButton}
          onClick={toggleOpen}
        >menu</button>
      </div>
    );
  }
}
