/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

/* tslint:disable:no-console */

export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
}

type StackFrame = {
  func?: string;
  file: string;
  line: number;
  col?: number;
};

function formatCaller(caller: string) : StackFrame|false {
  const match = caller.match(/([^@]+)@(.+):(\d+):(\d+)$/);
  if (!match) {
    return false;
  }
  const [, func, file, line, col ] = match;

  return {
    func,
    file: file.replace(/.*\/([^\/]+)/, '$1'),
    line: Number(line),
    col: Number(col),
  };
}

declare var IS_DEBUG : boolean|undefined;

type BasicNamed = { name: string };
type NamedClass = { constructor: { name: string } };
type Named = BasicNamed | NamedClass;

export class Logger<T extends Named> {
  public showStackTraces = false;
  private _logLevel: LogLevel = LogLevel.DEBUG;
  private prefix: string;

  constructor(private parent: T, private extraPrefix: string = '') {
    if (!parent) {
      throw new Error(`Logger requires a parent object or class`);
    }
    let parentClass = '';
    if ('name' in parent) {
      parentClass = (this.parent as BasicNamed).name;
    } else if ('constructor' in parent) {
      parentClass = (this.parent as NamedClass).constructor?.name;
    }
    this.prefix = `${parentClass ? `${parentClass}::` : ''}${this.extraPrefix}`;
  }

  public setLevel(level: LogLevel) {
    this._logLevel = level;

    return this;
  }

  public debug(message: string, ...args: any[]) {
    this.log(LogLevel.DEBUG, message, ...args);
  }

  public info(message: string, ...args: any[]) {
    this.log(LogLevel.WARN, message, ...args);
  }

  public warn(message: string, ...args: any[]) {
    this.log(LogLevel.INFO, message, ...args);
  }

  public error(message: string, ...args: any[]) {
    this.log(LogLevel.ERROR, message, ...args);
  }


  public log(level: LogLevel, ...args: any[]) {
    if (this._logLevel < level) {
      return;
    }

    let caller= '';
    const rawStack : string|undefined = new Error().stack;
    const stack = rawStack?.split('\n');

    if (stack && stack.length) {
      while (stack.length && stack[0]?.match(/(Logger:|log|debug|warn|info)@/)) {
        stack.shift();
      }

      if (stack.length) {
        const callerData = formatCaller(stack[0]);
        if (callerData !== false) {
          // fileInfo = `${caller.file}:${caller.line}`;
          caller = `${callerData.func ? `${callerData.func}()` : ''}: `;
        }
      }
    }

    let message = '';

    if (args.length && typeof(args[0]) === 'string') {
      message = args.shift();
    }


    switch (level) {
    case LogLevel.ERROR:
      console.error(`${this.prefix}${caller}${message}`, ...args);
      console.trace();
      break;
    case LogLevel.WARN:
      console.warn(`${this.prefix}${caller}${message}`, ...args);
      console.trace();
      break;
    case LogLevel.INFO:
      console.info(`${this.prefix}${caller}${message}`, ...args);
      if (this.showStackTraces) {
        console.trace();
      }
      break;
    default:
      if (typeof (IS_DEBUG) === 'undefined' || !IS_DEBUG) {
        return;
      }
      if (this.showStackTraces) {
        console.trace(`${this.prefix}${caller}${message}`, ...args);
      } else {
        console.log(`${this.prefix}${caller}${message}`, ...args);
      }
      break;
    }
  }
}

const defaultLogger = new Logger({ name: '' }).setLevel(LogLevel.DEBUG);

export const debug = (message: string, ...args: any[]) =>
  defaultLogger.debug(message, ...args);
export const info = (message: string, ...args: any[]) =>
  defaultLogger.info(message, ...args);
export const warn = (message: string, ...args: any[]) =>
  defaultLogger.warn(message, ...args);
export const error = (message: string, ...args: any[]) =>
  defaultLogger.error(message, ...args);

