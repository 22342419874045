// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".commandbutton__button___W7eGy{font-size:.875em;background-color:#77b55a;border-radius:4px;border:1px solid #1d2e15;-webkit-box-shadow:0px 6px 10px -6px #497534;box-shadow:0px 6px 10px -6px #497534;color:#fff;cursor:pointer;display:inline-block;font-weight:bold;padding:.5rem 1rem;text-decoration:none;text-shadow:0px 1px 0px #5f9844;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-size:1.5rem}.commandbutton__button___W7eGy:active:not([disabled]){-webkit-transform:translateY(1px);transform:translateY(1px);-webkit-box-shadow:0px 4px 7px -5px #497534;box-shadow:0px 4px 7px -5px #497534}.commandbutton__button___W7eGy:hover:not([disabled]),.commandbutton__button___W7eGy:focus:not([disabled]){background-color:#70da3f}@media screen and (max-device-width: 600px){.commandbutton__button___W7eGy{padding:1rem;width:auto;height:auto}}@media screen and (max-device-height: 600px){.commandbutton__button___W7eGy{padding:1rem;width:auto;height:auto}}", ""]);
// Exports
exports.locals = {
	"button": "commandbutton__button___W7eGy"
};
export default exports;
