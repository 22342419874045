/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { joinSounds, successSounds } from '@/soundList';
import { randomElement } from '@/util';

export function randomSound() {
  return randomElement(joinSounds);
}

export function randomSuccessSound() {
  return randomElement(successSounds);
}
