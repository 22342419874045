/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { IJigsaw, Jigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import { JigsawCommand } from './jigsawCommand';

export class SnapPiecesCommand extends JigsawCommand {
  public result = false;

  constructor(private piece: Piece) {
    super();
  }

  public run(jigsaw: IJigsaw) {
    this.result = (jigsaw as Jigsaw).snapPieces(this.piece, true);
  }
}

