/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect, Side, Vec } from '@/geom';
import { CanvasRenderer, SVGRenderer } from '@/renderer';

import type { Immutable, XYArgs } from '@/jtypes';
import type { AbstractRenderer } from '@/renderer';
import type { Tongue, TonguePoint } from '@/tongue';

// import * as logger from '@/logger';

export type PieceSide = false | Tongue;
export type PieceSidePoints = [Point, Point];

type PieceDrawOptions = Partial<{
  offset: Immutable<Point>;
  showConstruction: boolean;
}>;

type SVGOptions = PieceDrawOptions & {
  outputPuzzle: boolean;
};

export type PieceSettings = {
  angle: number;
  column: number;
  h: number;
  pos: Point;
  row: number;
  sides: PieceSide[] ;
  sourceRect: Rect;
  visible: boolean;
  w: number;
  x: number;
  y: number;
};

export class Piece {
  public color = 'rgba(34,176,181,0.7)';
  public isVisible = true;

  public angle = 0;

  public baseWidth = 0;
  public baseHeight = 0;

  public offset = new Point();
  public sides: PieceSide[] = [];
  public sourceRect = new Rect();

  private _pos = new Point();
  private _bounds = new Rect();

  // this are readonly from outside
  // getters are provided
  private _isEdge = true;
  private _column = 0;
  private _row = 0;

  private points: Point[] = [new Point(), new Point(), new Point(), new Point()];

  constructor(args: Partial<PieceSettings> = {}) {
    const {
      pos,
      x,
      y,
      w, h,
      row,
      column,
      sourceRect,
      sides,
      ...assign
    } = args;

    Object.assign(this, assign);

    this._row = row ?? 0;
    this._column = column ?? 0;
    this.baseWidth = w ?? 0;
    this.baseHeight = h ?? 0;

    if (sourceRect) {
      this.sourceRect.set(sourceRect);
    }

    if (pos) {
      this._pos.set(pos);
    } else {
      this._pos.set(x ?? 0, y ?? 0);
    }

    this.sides = sides?? this.sides;
    this.updatePoints();
    this.updateDimensions();
  }

  get column() { return this._column; }

  get id() : string {
    return `${this.column}_${this.row}`;
  }

  get isEdge() { return this._isEdge; }

  get row() { return this._row; }

  public getPos() : Immutable<Point> {
    return this._pos;
  }

  public setPos(p: Immutable<Point>) {
    this._pos.set(p);
  }

  public calcSnapTo(otherPiece: Immutable<Piece>, newPos: Point) : boolean {
    // project other piece into our rotation etc.
    // const { pos, angle } = otherPiece.projectTo(this);
    // console.log('snapTo', pos, angle);
    const rowDiff = Math.abs(otherPiece.row - this.row);
    const colDiff = Math.abs(otherPiece.column - this.column);

    if (colDiff > 1 || rowDiff > 1) {
      return false;
    }

    if (otherPiece.row > this.row) {
      // other piece is below us
      if (otherPiece.column !== this.column) {
        return false;
      }
      newPos.set(otherPiece.getPos().x, otherPiece.getPos().y - otherPiece.baseHeight);
    } else if (otherPiece.row < this.row) {
      if (otherPiece.column !== this.column) {
        return false;
      }
      newPos.set(otherPiece.getPos().x, otherPiece.getPos().y + otherPiece.baseHeight);
    } else {
      if (otherPiece.column < this.column) {
        newPos.set(otherPiece.getPos().x + otherPiece.baseWidth, otherPiece.getPos().y);
      } else if(otherPiece.column > this.column) {
        newPos.set(otherPiece.getPos().x - otherPiece.baseWidth, otherPiece.getPos().y);
      }
    }

    return true;
  }

  public canFitInto(otherPiece: Piece) : boolean {
    const rowDiff = Math.abs(otherPiece.row - this.row);
    const colDiff = Math.abs(otherPiece.column - this.column);

    if (colDiff > 1 || rowDiff > 1) {
      return false;
    }
    const thisBounds = this.getInnerRect();
    const otherBounds = otherPiece.getInnerRect();
    const { distanceSq, side } = thisBounds.distanceByMidpoints(otherBounds);

    const oppositeTongue = otherPiece.sides[side] as Tongue;
    const thisTongue = this.sides[(side + 2) % 4] as Tongue;
    let minDist = 0;

    if (oppositeTongue && thisTongue) {
      minDist = Math.max( oppositeTongue.extentPx, thisTongue.extentPx );
      minDist = minDist * minDist;
    }

    const canFit =
      (side === Side.TOP && this.row < otherPiece.row && distanceSq <= minDist)
      || (side === Side.BOTTOM && this.row > otherPiece.row && distanceSq <= minDist)
      || (side === Side.LEFT && this.column < otherPiece.column && distanceSq <= minDist)
      || (side === Side.RIGHT && this.column > otherPiece.column && distanceSq <= minDist)
      ;

    // logger.debug(`[${this.id}].canFitInto(${otherPiece.id}) -> d ${distance} (< ${minDist} ?) s ${sideNames[side]} → ${canFit ? 'yes' : 'no'}`);
    return canFit;
  }

  public draw(renderer: AbstractRenderer, options: PieceDrawOptions = {}) : void {
    const { offset = new Point(0,0) } = options;
    // const { x, y, width, height } = this.calcDimensions();

    const points = this.points.map(p => p.plus(offset).ceil());

    renderer.beginPath();
    renderer.moveTo(points[0].x, points[0].y);

    for (let i = 0; i < points.length; i++) {
      const sidePoints : PieceSidePoints = [points[i], points[(i + 1) % points.length]];
      this.drawSide(
        renderer,
        sidePoints,
        this.sides[i],
        { ...options },
      );
    }
    renderer.closePath();
  }

  public drawConstruction(renderer: AbstractRenderer) : void {
    const offset = new Point(0, 0);

    const points = this.points.map(p => p.minus(this.points[0]).plus(offset));

    renderer.moveTo(points[0].x, points[0].y);

    for (let i = 0; i < points.length; i++) {
      const sidePoints : PieceSidePoints = [points[i], points[(i + 1) % points.length]];
      this.drawSide(renderer,
        sidePoints,
        this.sides[i],
        { showConstruction: true },
      );
    }
  }

  public getBoundingRect(target?: Rect) : Readonly<Rect> {
    if (!target) {
      target = new Rect();
    }

    return target.set(this._bounds).offset(this._pos.x, this._pos.y).ceil();
  }

  public getInnerRect() : Readonly<Rect> {
    return Rect.fromXYWidthHeight(
      this._pos.x, this._pos.y,
      this.baseWidth, this.baseHeight,
    );
  }


  public setTongue(sideIdx: Side, tongue: PieceSide) : void {
    this.sides[sideIdx] = tongue;
    this.updatePoints();
    this.updateDimensions();
  }

  public toPath(options: PieceDrawOptions = {}) : Path2D {
    const renderer = new CanvasRenderer(new Path2D());
    const offset = options.offset ?? this.offset.clone().ceil();
    this.draw(renderer, { ...options, offset });

    return renderer.getPath();
  }


  public toSVG(options: SVGOptions = { outputPuzzle: false }) : string {
    const b = this.getBoundingRect();
    const svg = new SVGRenderer(Rect.fromWidthHeight(b.width, b.height));
    const offset = new Point(0, 0);
    this.draw(svg, { offset, ...options });

    return svg.toString(!options.outputPuzzle);
  }

  public updateDimensions() : void {
    const r = this.calcDimensions();
    this.offset.set(r.topLeft.times(-1)).ceil();
    this._bounds.set(r).ceil();
  }

  private calcDimensions() : Rect {
    const r = Rect.fromWidthHeight(this.baseWidth, this.baseHeight);

    this.sides.forEach((tongue, i) => {
      if (tongue) {
        const side : PieceSidePoints = [ this.points[i], this.points[(i+1) % this.points.length]];
        const px = tongue.extentInPixels(side, this);

        // top side, update y offset
        if (i === Side.TOP) {
          r.pos.y -= px;
        }
        // left side, update x offset
        if (i === Side.LEFT) {
          r.pos.x -= px;
        }

        // odd sides are left, right
        if (!!(i & 1)) { // tslint:disable-line
          r.width += px;
        } else {
          r.height += px;
        }
      }
    });

    return r.ceil();
  }

  private drawSide(renderer: AbstractRenderer, sidePoints: PieceSidePoints, tongue: PieceSide = false, options: PieceDrawOptions = {}) {
    if (tongue) {
      this.drawTongue(renderer, sidePoints, tongue, options);
    } else {
      if (options.showConstruction) {
        renderer.moveTo(sidePoints[0].x, sidePoints[0].y);
      } else {
        renderer.lineTo(sidePoints[0].x, sidePoints[0].y);
      }
    }
    if (options.showConstruction) {
      const vSide = Vec.fromTwoPoints(...sidePoints);
      const normal = vSide.rotate90CCW().normalize();
      const mid = sidePoints[0].plus(vSide.times(0.5));

      renderer.strokeStyle = 'rgb(255,0,255)';
      renderer.moveTo(mid.x, mid.y);
      renderer.lineTo(...[...mid.plus(normal.times(40))] as XYArgs);
      renderer.stroke();
    }
  }

  private drawTongue(renderer: AbstractRenderer, side: PieceSidePoints, tongue: Tongue, options: PieceDrawOptions = {}) {
    const tonguePts : TonguePoint[] = tongue.calcPoints(side);
    const [ firstPt, lastPt ] : PieceSidePoints = side;
    const pp : TonguePoint[] = [ firstPt, ...tonguePts, lastPt ];
    const { showConstruction } = options;

    let prevPt : TonguePoint|null = null;

    for (const p of pp) {

      if (p instanceof Array) {
        const [cp1, cp2, anchor] = p;
        if (showConstruction && prevPt instanceof Point) {
          renderer.strokeStyle = 'rgb(100,100,100)';
          renderer.moveTo(prevPt.x, prevPt.y);
          renderer.lineTo(cp1.x, cp1.y);
          renderer.stroke();
          renderer.moveTo(cp2.x, cp2.y);
          renderer.lineTo(anchor.x, anchor.y);
          renderer.stroke();
          renderer.strokeStyle = 'rgb(0,0,0)';
        } else {
          renderer.bezierCurveTo(cp1, cp2, anchor);
        }
      } else if (!showConstruction) {
        renderer.lineTo(Math.ceil(p.x), Math.ceil(p.y));
      }
      prevPt = p;
    }

    // if (showConstruction) {
    //   for (const p of pp) {
    //     if (p instanceof Array) {
    //       const [cp1, cp2, anchor] = p;
    //       // dot(...anchor, [255, 0, 255], { context: renderer });
    //       // dot(...cp1, [0, 255, 255], { context: renderer, radius: dotRadius/2 + 1 });
    //       // dot(...cp2, [255, 255, 0], { context: renderer, radius: dotRadius/2 + 1 });
    //     } else {
    //       // dot(...p, undefined, { context: renderer });
    //     }
    //   }
    // }
  }

  private updatePoints() : void {
    const { baseWidth, baseHeight } = this;

    this.points[Side.TOP].set(0, 0);
    this.points[Side.RIGHT].set(baseWidth, 0);
    this.points[Side.BOTTOM].set(baseWidth, baseHeight);
    this.points[Side.LEFT].set(0, baseHeight);

    let numTongues = 0;

    this.sides.forEach((tongue, sideIdx) => {
      if (tongue) {
        ++numTongues;
        const sidePoints : PieceSidePoints = [this.points[sideIdx], this.points[(sideIdx + 1) % this.points.length]];
        tongue.extentInPixels(sidePoints, this);
      }
    });
    this._isEdge = numTongues < 4;
  }
}
