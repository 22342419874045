/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Rect } from '@/geom';
import { NullJigsaw } from '@/jigsaw';
import { Engine } from '@/physics';
import { PiecePosition } from '@/piecePosition';

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import type { IEventEmitter } from '@/eventEmitter';
import type { Point } from '@/geom';
import type { IJigsaw } from '@/jigsaw';
import type { Immutable } from '@/jtypes';

export interface Hook<T extends IEventEmitter> {
  apply(emitter: T, ...args: any[]) : void;
  unapply(emitter: T) : void;
}

// export interface HookFactory<T> {
//   new(owner: T) => Hook<T extends EventEmitter>;
// }


export class PhysicsHook implements Hook<ReactJigsaw> {
  private static readonly BoundsConstraintPadding = 40;
  private static readonly FrictionCoeff = 0.8;
  private static readonly RestitutionFactor = 0.7;

  private isPhysicsRunning = true;
  private bounds: Rect = new Rect();

  private engine: Engine;
  private app: ReactJigsaw|null = null;
  private jigsaw: IJigsaw = new NullJigsaw();

  constructor(bounds?: Immutable<Rect>) {
    if (bounds) {
      this.bounds.set(bounds);
    }
    this.engine = new Engine();
    this.engine.on('finished', () => {
      this.isPhysicsRunning = false;
    });
  }

  public setConstraintBounds(bounds: Immutable<Rect>) {
    this.bounds.set(bounds);
  }

  public apply(app: ReactJigsaw, jigsaw: IJigsaw) {
    this.app = app;
    this.jigsaw = jigsaw;

    app.on('reset-pieces', this.onResetPieces);
    app.on('begin-physics', this.onPreDraw);
  }

  public onPreDraw = () => {
    if (this.isPhysicsRunning) {
      this.engine.step(1000/60); // @todo use real timestep
      // this.updateRegion.reset();
    }
  }

  public onResetPieces = () => {
    if (!this.app) {
      throw new Error(`No app set`);
    }
    this.engine.clear();

    for (const piece of this.jigsaw) {
      if (!this.jigsaw.groups.isPieceInAnyGroup(piece)) {
        this.engine.addBody(new PiecePosition(piece, this.jigsaw), {
          frictionCoeff: PhysicsHook.FrictionCoeff,
          constrain: this.constrain,
        });
      }
    }
  }

  public start() {
    this.isPhysicsRunning = true;
    this.engine.step(1000/60); // @todo use real timestep
  }

  public stop() {
    this.isPhysicsRunning = false;
    this.engine.clearVelocities();
  }

  public isRunning() {
    return this.isPhysicsRunning;
  }

  public unapply(app: ReactJigsaw) {
    this.app = null;
    app.off('reset-pieces', this.onResetPieces);
    app.off('begin-physics', this.onPreDraw);
  }

  private constrain = (p: Point) => {
    const bounds = this.bounds;
    const { BoundsConstraintPadding, RestitutionFactor } = PhysicsHook;
    const pB = bounds.clone().inset(BoundsConstraintPadding);

    p.x = Math.max(pB.x, Math.min(pB.bottomRight.x, p.x));
    p.y = Math.max(pB.y, Math.min(pB.bottomRight.y, p.y));
  }

  private constrainRebound = (p: Point) => {
    const { BoundsConstraintPadding, RestitutionFactor } = PhysicsHook;
    const bounds = this.bounds;
    // const pad = BoundsConstraintPadding; //(bounds.w / this.columns) / 2;
    const physicsBounds = bounds.clone().inset(BoundsConstraintPadding);

    if (p.x < physicsBounds.x) {
      const x = physicsBounds.x - p.x;
      p.x = x * RestitutionFactor;
    }
    if (p.y < physicsBounds.y) {
      const y = physicsBounds.y - p.y;
      p.y = y * RestitutionFactor;
    }
    if (p.x > physicsBounds.bottomRight.x) {
      const x = physicsBounds.bottomRight.x -
        (p.x - physicsBounds.bottomRight.x) * RestitutionFactor;
      p.x = x;
    }
    if (p.y > physicsBounds.bottomRight.y) {
      const y = physicsBounds.bottomRight.y -
        (p.y - physicsBounds.bottomRight.y) * RestitutionFactor;
      p.y = y;
    }
  }
}
