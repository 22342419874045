/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Piece } from '@/piece';

type GroupMap = Map<string, Piece[]>;

export class PieceGrouper {
  private groups: GroupMap = new Map();

  public areInSameGroup(p1: Piece, p2: Piece) : boolean {
    const grp1 = this.groups.get(p1 && p1.id);
    const grp2 = this.groups.get(p2 && p2.id);

    return grp1 !== undefined && grp1 === grp2;
  }

  public getGroupFor(piece: Piece) : Piece[]|undefined {
    return this.groups.get(piece && piece.id);
  }

  public getOtherGroupMembers(piece: Piece) : Piece[] {
    const members = this.getGroupFor(piece);
    if (!members) {
      return [];
    }

    return members.filter(p => p !== piece);
  }

  public set(piece: Piece, group: Piece[]) {
    this.groups.set(piece.id, group);
  }

  public isPieceInAnyGroup(p: Readonly<Piece>) {
    return p && this.groups.has(p.id);
  }

  public splitPieceFromGroups(piece: Piece) {
    const grp = this.groups.get(piece.id);
    if (grp) {
      grp.splice(grp.indexOf(piece), 1);
      this.groups.delete(piece.id);

      if (grp.length === 1) {
        this.splitPieceFromGroups(grp[0]);
      }
    }
  }

  /**
   * Merge the two pieces (and their groups) into a new group
   *
   * @param  {Piece}   pieceA
   * @param  {Piece}   pieceB
   * @return {boolean}        Return true if the groups were merged, false if
   *                                 nothing changed
   */
  public mergePieceGroups(pieceA: Piece, pieceB: Piece) : boolean {
    const pieceAGroup = this.getGroupFor(pieceA) || [ pieceA ];
    const pieceBGroup = this.getGroupFor(pieceB) || [ pieceB ];

    if (pieceAGroup === pieceBGroup) {
      return false;
    }

    const newGroup = [ ...pieceAGroup, ...pieceBGroup ];
    this.set(pieceA, newGroup);
    this.set(pieceB, newGroup);

    newGroup.forEach(piece => this.set(piece, newGroup));

    return true;
  }

}
