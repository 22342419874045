/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Immutable } from '@/jtypes';
import type { Point } from './point';
import type { Rect } from './rect';
import type { Vec } from './vec';

export interface PointInterface {
    x: number;
    y: number;
}

export type XY = PointInterface;

export interface Line {
  p: PointRO;
  v: Immutable<Vec>;
}

export type WxH = {
  width: number;
  height: number;
};

export enum Side {
  TOP = 0,
  RIGHT = 1,
  BOTTOM = 2,
  LEFT = 3,
  INVALID = 4,
}

/**
 * Valid params to some functions expecting a point; or x,y; or nothing
 * @type {Array}
 */
export type PointInit = [PointInterface] | [number, number] | [];


export type PointRO = Readonly<Point>;
export type RectRO= Immutable<Rect>;
