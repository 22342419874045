/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import {
  FullscreenCommand,
  HintCommand,
  LowerPieceCommand,
  MovePieceCommand,
  PhysicsCommand,
  PhysicsForceCommand,
  RaisePieceCommand,
  ScatterCommand,
  SeparatePieceCommand,
  SnapPiecesCommand,
  ToggleEdgesCommand,
} from '@/commands';
import { Vec } from '@/geom';
import * as logger from '@/logger';
import { $, cursor } from '@/util';

import { NullInputHandler } from './nullInputHandler';

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import type { EventData } from '@/eventHandler';
import type { Piece } from '@/piece';
import type { IInputHandler } from './types';

const MOUSE_BUTTON_MIDDLE = 4; // see MouseEvent.buttons

export class JigsawInputHandler extends NullInputHandler implements IInputHandler  {
  private currentPiece: Piece|false = false;
  private isPushing = false;
  private isMovingGroup = false;
  private wasMouseDownWithinBounds = false;

  constructor(private app: ReactJigsaw) {
    super();
  }

  public onKeyDown(event: EventData) {
    switch (event.key) {
      case 's':
        this.app.exec(new ScatterCommand());
        break;
      case 'h':
        this.app.exec(new HintCommand());
        break;
      case 'f':
        this.app.exec(new FullscreenCommand());
        break;
      case '?':
      case 'h':
        // this.emit('help');
        break;
      case 'e':
        this.app.exec(new ToggleEdgesCommand(ToggleEdgesCommand.Mode.Toggle));
        break;
    }
  }

  public onMouseDown(event: EventData) {
    this.wasMouseDownWithinBounds = event.isWithinBounds;

    if (!event.isWithinBounds) {
      return;
    }
    event.rawEvent.stopPropagation();

    this.app.exec(new PhysicsCommand(PhysicsCommand.STOP));
    this.isMovingGroup = false;

    if (event.modifiers.Shift) {
      this.currentPiece = false;

      return;
    }

    const isReverse = event.isKeyDown('Alt') || (event.rawEvent as MouseEvent).button !== 0;
    const piece = this.app.findPieceByPoint(event.mousePos, {
      isReverse,
    });

    if (piece) {
      this.app.exec(new RaisePieceCommand(piece));
      logger.debug('current piece', piece);
    }

    this.currentPiece = piece;

    if (piece) {
      cursor('none');
      if (event.isKeyDown('Control')) {
        this.app.exec(new SeparatePieceCommand(piece));
      } else {
        this.isMovingGroup = this.app.isPiecePartOfGroup(piece);
      }
    }
  }


  public onMouseDragged(event: EventData) {
    if (!this.wasMouseDownWithinBounds) {
      return;
    }

    event.rawEvent.preventDefault();

    // tslint:disable-next-line: no-bitwise
    if (((event.rawEvent as MouseEvent).buttons & MOUSE_BUTTON_MIDDLE) || event.modifiers.Shift) {
      cursor('move');
      this.isPushing = true;
      this.app.exec(new PhysicsForceCommand({
          pos: event.mousePos.clone(),
          force: new Vec(event.mouseDelta.x, event.mouseDelta.y),
          strength: 1,
          radius: 120,
          radiusSq: 120*120,
      }));

      return;
    }

    this.isPushing = false;

    if (this.currentPiece) {
      const { mouseDelta } = event;
      // @todo use Flyweight for these commands.
      // only reason to store separate instances is if we implement Undo sometime.
      this.app.exec(new MovePieceCommand(this.currentPiece, mouseDelta.x, mouseDelta.y));
    }
  }

  public onMouseUp(event: EventData) {
    cursor('move');

    // clear any forces
    this.app.exec(new PhysicsForceCommand(null, true));

    if (this.isPushing) {
      // this.app.exec(new ScatterCommand(this.app));
      return;
    }

    if (!event.isWithinBounds) {
      return;
    }

    // @todo refactor this logic
    const { target } = (event.rawEvent as MouseEvent);

    if (this.wasMouseDownWithinBounds
      && this.currentPiece
      && !event.isKeyDown('Control')
    ) {
      const snap = new SnapPiecesCommand(this.currentPiece as Piece);
      this.app.exec(snap);

      if (!snap.result && this.isMovingGroup) {
        this.app.exec(new LowerPieceCommand(this.currentPiece as Piece));
      }
    }
  }
}

