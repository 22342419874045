/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { randRangeFloor } from '@/util';

export function shuffleArray<T>(list: T[]) : T[] {
  const out = list.slice();

  return shuffleArrayInPlace(out);
}

export function shuffleArrayInPlace<T>(list: T[]) : T[] {
  const midPoint = list.length / 2;

  for (let i = list.length-1; i >= midPoint; i--) {
    const swapIdx = Math.floor(Math.random() * (midPoint-1));
    ([list[i], list[swapIdx]] = [list[swapIdx], list[i]]);
  }

  return list;
}
