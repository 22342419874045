/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect, Vec } from '@/geom';

import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import type { Perturber } from './types';

export class Scatterer implements Perturber {
  constructor() {
  }

  public run(pieces: Piece[], jigsaw: IJigsaw) : void {
    const force = new Vec();
    const newPos = new Point();
    const showOnlyEdges = false /* @todo get from previously jigsaw.isDrawingOnlyEdges */;

    for (const pieceToScatter of pieces) {
      if (showOnlyEdges && !pieceToScatter.isEdge) {
        continue;
      }

      if (jigsaw.groups.isPieceInAnyGroup(pieceToScatter)) {
        continue;
      }

      this.calcRepellingForce(pieceToScatter, pieces, showOnlyEdges, force);

      newPos.set(pieceToScatter.getPos()).add(force);

      jigsaw.movePieceTo(pieceToScatter, newPos.x, newPos.y, { dirty: false, groups: false });
    }
  }

  protected calcRepellingForce(pieceToScatter: Piece, pieces: Piece[], showOnlyEdges: boolean, force: Vec) {
    const vOther = new Vec();
    let sqlen = 0;

    const oRect = new Rect();
    const pRect = new Rect();
    const rIntersect = new Rect();
    pieceToScatter.getBoundingRect(pRect);
    const tooClose = pRect.width * pRect.height;

    force.set(0, 0);

    for (const otherPiece of pieces) {
      if (showOnlyEdges && !pieceToScatter.isEdge) {
        continue;
      }

      vOther.set(otherPiece.getPos()).sub(pieceToScatter.getPos());
      sqlen = vOther.sqlen;

      if (sqlen > tooClose) {
        continue;
      }

      if (vOther.sqlen < Number.EPSILON) {
        const scale = 0.1;
        // basically coincident, add a random force
        force.add(new Vec(
          (Math.random() * pRect.width - pRect.width / 2) * scale,
          (Math.random() * pRect.height - pRect.height / 2) * scale),
        );
        continue;
      }

      otherPiece.getBoundingRect(oRect);
      Rect.intersect(pRect, oRect, rIntersect);

      if (rIntersect.isEmpty()) {
        try {
          // work out actual distance between rect bounds
          Rect.getEdgeDistanceVector(pRect, oRect, vOther);
        }
         catch (error) {
          continue;
        }
      } else {
        vOther.setLength(Vec.fromTwoPoints(rIntersect.topLeft, rIntersect.bottomRight).length);
      }

      // vOther.scale(1.0 / vOther.length).scale(0.0001);
      force.add(vOther.scale(-1 / vOther.length));
    }
  }
}
