/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

type ModeOptions = {
  onlyDrawEdgePieces: boolean;
};

export type JigsawModeOption =  keyof ModeOptions;

export class JigsawMode {

  private settings: { [key: string] : boolean } = {
    onlyDrawEdgePieces: false,
  };

  constructor(private _isChanged = false) {
  }

  get isChanged() { return this._isChanged; }


  get onlyDrawEdgePieces() { return this.settings.onlyDrawEdgePieces; }

  public toggle(name: string) : void {
    this.settings[name as string] = !this.settings[name as string];
    this._isChanged = true;
  }

  public set(name: string, value: boolean) : void {
    this.settings[name] = value;
    this._isChanged = true;
  }

  public unflag() : void {
    this._isChanged = false;
  }
}
