/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Dim } from '@/geom';
import { Rect } from '@/geom';
import type { Immutable } from '@/jtypes';

export function calcCropScale(imageDim: Immutable<Dim>, targetDim: Immutable<Dim>) {
  const isLandscapeImage = imageDim.width > imageDim.height;
  const isLandscapeTarget = targetDim.isLandscape;
  const imageRatio = imageDim.aspectRatio;

  let scale = 1;

  if (isLandscapeTarget) {
    // smaller image ratio means more portrait
    if (imageRatio <= targetDim.aspectRatio) {
      // scale image width to meet canvas width
      scale = imageDim.width / targetDim.width;
    } else {
      // scale image height to meet canvas height
      scale = imageDim.height / targetDim.height;
    }
  } else {
    if (imageRatio <= targetDim.aspectRatio) {
      // scale image height to meet canvas height
      scale = imageDim.height / targetDim.height;
    } else {
      // scale image width to meet canvas width
      scale = imageDim.height / targetDim.height;
    }
  }

  return Rect
    .fromWidthHeight(targetDim.w * scale, targetDim.h * scale)
    .floor();
}
