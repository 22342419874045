/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { EventData } from '@/eventHandler';
import type { IInputHandler } from './types';

export class NullInputHandler implements IInputHandler {
  public onKeyDown(event: EventData) { /* NOOP */ }
  public onKeyUp(event: EventData) { /* NOOP */ }
  public onMouseDown(event: EventData) { /* NOOP */ }
  public onMouseDragged(event: EventData) { /* NOOP */ }
  public onMouseMoved(event: EventData) { /* NOOP */ }
  public onMouseUp(event: EventData) { /* NOOP */ }
}
