/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as React from 'react';

import { CanvasMarginFactor, DefaultNumPieces, MobileDeviceWidth, PhysicsTimeScale } from '@/constants';

import { AppCommand, JigsawCommand } from '@/commands';
import { DirtyRectangles } from '@/dirty';
import { EventEmitter } from '@/eventEmitter';
import { BaseEventHandler, EventHandler } from '@/eventHandler';
import { Dim, Point, Rect } from '@/geom';
import { PhysicsHook } from '@/hook';
import { ImageLoader, ImageSource } from '@/imageLoader';
import { IInputHandler, JigsawInputHandler, NullInputHandler } from '@/input';
import { Jigsaw, JigsawDrawOption, NullJigsaw } from '@/jigsaw';
import { Constrainer, Scatterer, ScatterSelected, Shuffler } from '@/perturbers';
import { calcForceForPiece, Force } from '@/pusher';
import { CanvasRenderer, NullRenderer } from '@/renderer';

import classNames from '@/helpers/classNames';
import debounce from '@/helpers/debounce';
import { getImageFromLocation } from '@/helpers/getImageFromLocation';
import { isMobile } from '@/helpers/isMobile';
import { promiseFirst } from '@/helpers/promiseFirst';
import randomImage from '@/helpers/randomImage';
import { randomSound, randomSuccessSound } from '@/helpers/randomSound';
import setStatePromised from '@/helpers/setStatePromised';
import { $, absolutePath } from '@/util';

import * as vectors from '@/geom';
import * as logger from '@/logger';

import { CommandButton } from '@/components/CommandButton';
import { Controls, ControlsProps } from '@/components/Controls';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { MobileMenu } from '@/components/MobileMenu';
import { PreviewImage } from '@/components/PreviewImage';
import { Slot } from '@/components/Slot';
import { TopButtons } from '@/components/TopButtons';

import type { ICommand } from '@/commands';
import type { EventEmitterCallback, IEventEmitter } from '@/eventEmitter';
import type { IJigsaw } from '@/jigsaw';
import type { Piece } from '@/piece';
import type { AbstractRenderer } from '@/renderer';

import styles from '@/styles/index.scss';

let hasAuthed = false;

const proxyURL = IS_DEBUG ? 'https://palenque.local:8000' : 'https://jigsaw.speak.geek.nz';

export type ReactInitProps = ControlsProps & { audio?: string; columns: number; rows: number };

declare global {
  interface Window {
    app: ReactJigsaw;
    jigsaw: IJigsaw;
    pieces: Piece[];
    v: any;
  }
}

type Props = {
};

type LocationOptions = {
  url: string;
  numPieces: number;
  crop: Rect;
  showPreview: boolean;
};

type ImageData = {
  title?: string;
  url: string;
  metadata?: object;
  link?: string;
};

type State = {
  audio: string;
  image: ImageData;
  backgroundColor: string;
  columns: number;
  isDrawingOnlyEdges: boolean;
  isLoading: boolean;
  isShuffled: boolean;
  isSidebarHidden: boolean;
  lastError: Error|null;
  numPieces: number;
  rows: number;
  location: LocationOptions;
};

type ParsedLocationOptions = Partial<{
  showPreview: boolean;
  numPieces: number;
}>;

const taskProgressWeights = new Map([
  ['load image', 50],
  ['cut piece', 20],
  ['draw piece', 30],
]);


// state is never set so we use the '{}' type.
export class ReactJigsaw extends React.Component<Props, State> implements IEventEmitter {

  public jigsaw: IJigsaw = new NullJigsaw();


  private canvasRef: React.RefObject<HTMLCanvasElement>;
  private audioRef: React.RefObject<HTMLAudioElement>;
  private emitter = new EventEmitter();

  private eventHandler: BaseEventHandler;
  private forces: Force[] = [];
  private inputHandler: IInputHandler = new NullInputHandler();
  private loader: ImageLoader;
  private updateRegion: DirtyRectangles = new DirtyRectangles();
  private isResizeHookEnabled = true;

  private onWindowResize = debounce(() => {
    if (this.isResizeHookEnabled) {
      this.resized();
    }
  }, 500);

  private physics: PhysicsHook;
  private renderer: AbstractRenderer;

  private lastT = -1; // for timestamps & physics
  private animID = -1; // result of requestAnimationFrame

  constructor(props: Props) {
    super(props);

    this.canvasRef = React.createRef();
    this.audioRef = React.createRef();
    this.eventHandler = new BaseEventHandler();
    this.loader = new ImageLoader();
    this.physics = new PhysicsHook();
    this.renderer = new NullRenderer();
    this.inputHandler = new JigsawInputHandler(this);

    this.state = {
      audio: randomSound(),
      image: {
        url: '',
      },
      backgroundColor: getComputedStyle(document.body)?.backgroundColor ?? 'white',
      lastError: null,
      isShuffled: false,
      isDrawingOnlyEdges: false,
      isLoading: true,
      isSidebarHidden: false,
      rows: 1, columns: 1,
      numPieces: DefaultNumPieces,
      location: {
        url: '',
        crop: new Rect(),
        showPreview: true,
        numPieces: DefaultNumPieces,
      },
    };

    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('orientationchange', this.onWindowResize);
  }

  public componentDidMount() {
    document.querySelector('#mobile button')?.addEventListener('click', this.onMobileMenu);
    this.init();
  }

  public componentWillUnmount() {
    document.querySelector('#mobile button')?.removeEventListener('click', this.onMobileMenu);
  }

  public on(eventName: string, cb: EventEmitterCallback) : void {
    this.emitter.on(eventName, cb);
  }

  public oncePromised(eventName: string) : Promise<any> {
    return this.emitter.oncePromised(eventName);
  }

  public once(eventName: string, cb?: EventEmitterCallback) : Promise<any> {
    return this.emitter.once(eventName, cb);
  }

  public off(eventName: string, cb: EventEmitterCallback) : void {
    return this.emitter.off(eventName, cb);
  }

  public emit(eventName: string, data?: any) : boolean {
    return this.emitter.emit(eventName, data);
  }


  public exec<T>(cmd: ICommand<T>) {
    if (cmd instanceof AppCommand) {
      cmd.run(this);
    } else if (cmd instanceof JigsawCommand) {
      cmd.run(this.jigsaw);
    }
    cmd.markAsFinished();
  }

  public findPieceByPoint(pos: Readonly<Point>, { isReverse } = { isReverse: false }) : Piece|false {
    return this.jigsaw.findPieceByPoint(pos, {
      reverse: isReverse,
      onlyEdges: this.jigsaw.mode.onlyDrawEdgePieces,
    });
  }

  public getCanvasBounds() {
    const canvas = this.getCanvas();

    return Rect.fromXYWidthHeight(0,0, canvas.width, canvas.height);
  }

  public onAction = (name: string, data?: string) => {
    if (!this.state.isShuffled) {
      return;
    }
    switch (name) {
      case 'debug':
        ((this.jigsaw as unknown) as Jigsaw)?.debugCacher();
        break;
      case 'scatter':
        this.scatterPieces();
        break;
      case 'hint':
        this.showHint();
        break;
      case 'fullscreen':
        this.setFullscreen();
        break;
    }
  }

  public setFullscreen() {
    this.isResizeHookEnabled = false;

    const canvas = this.getCanvas();

    return canvas.requestFullscreen()
    .then(() => {
      this.isResizeHookEnabled = true;
      this.resized();
    })
    .catch(() => {
      this.isResizeHookEnabled = true;
    });
  }

  public setShowOnlyEdges(show: boolean = true) {
    this.setState({
      isDrawingOnlyEdges: show,
    });
    this.jigsaw?.mode.set('onlyDrawEdgePieces', show);
  }

  public getShowOnlyEdges() : boolean {
    return this.state.isDrawingOnlyEdges;
  }

  public redraw() {
    this.jigsaw?.markAsDirty();
  }

  public render() {
    const {
      onChangePieces,
      onChooseImage,
    } = this;

    const {
      columns,
      rows,
      backgroundColor,
      isLoading,
      isShuffled,
      isSidebarHidden,
      lastError,
      audio,
      image,
      isDrawingOnlyEdges,
    } = this.state;

    const controlsOptions = {
      rows, columns,
      image: image.url,
      enabled: isShuffled,
      backgroundColor,
      onChangePieces,
      onChooseImage,
      onChangeColor: (backgroundColor: string) => this.setState({ backgroundColor }, () => this.redraw()),
    };

    const controlsResetKey = [
      controlsOptions.rows*controlsOptions.columns,
    ].join('-');

    return (
      <ErrorBoundary>
        { lastError ? (
          <div className='error'>
            <h1>Error</h1>
            <p>{lastError?.message}</p>
            <pre><code>{lastError?.stack}</code></pre>
            <button onClick={() => { this.setState({ lastError: null }); }} className={styles.errorButton}>Ok</button>
          </div>
        ) : null }
        <div
          onMouseDown={this.handleShuffleClick}
          onTouchStart={this.handleShuffleClick}
          className={classNames({
            'canvas-container': true,
            'unshuffled': !isShuffled,
            'loading': isLoading,
          })}
        >
          <canvas
            onContextMenu={e => e.preventDefault()}
            ref={this.canvasRef}
            width='10'
            height='1'
          ></canvas>
        </div>
        <MobileMenu>
          <TopButtons
            className='mobileTop'
            tooltipSide='left'
            enabled={isShuffled}
            isShowingOnlyEdges={isDrawingOnlyEdges}
            isSidebarHidden={isSidebarHidden}
            onSidebarToggleClick={this.onSidebarToggleClick}
            app={this}
          >
            <CommandButton
              title='Show settings'
              icon='settings'
              onClick={() => {
                document.querySelector('.container')?.classList.toggle('sidebar');
              }}
              className='command-show-settings'
            />
          </TopButtons>
        </MobileMenu>
        <div className={classNames({
          info: true,
          [styles.infoCollapsed]: isSidebarHidden,
        })}>
          <div className='info__content'>
            <div style={{display: isSidebarHidden ? 'none' : ''}}>
              <div className='controls'>
                <Controls key={controlsResetKey} {...controlsOptions}>
                  <Slot>
                    <TopButtons
                      tooltipSide='bottom'
                      className='controlsTop'
                      enabled={isShuffled}
                      isShowingOnlyEdges={isDrawingOnlyEdges}
                      isSidebarHidden={isSidebarHidden}
                      onSidebarToggleClick={this.onSidebarToggleClick}
                      app={this}
                    >
                      <CommandButton
                        icon='circledx'
                        title='Close settings pane'
                        onClick={() => document.querySelector('.container')?.classList.toggle('sidebar')}
                        tooltipSide='right'
                        className='command-close-settings'
                      />
                    </TopButtons>
                  </Slot>
                  <Slot name='preview'>
                     <PreviewImage
                       onNewImage={() => this.loadNewImage().then(() => this.redraw())}
                       src={image.url}
                       title={image.title}
                       link={image.link}
                     />
                  </Slot>
                </Controls>
              </div>
            </div>
            <audio ref={this.audioRef} src={audio} preload='auto'></audio>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  public startPhysics() {
    this.physics.start();
  }

  public stopPhysics() {
    this.physics.stop();
  }

  /* @todo move this to perturber or something */
  public addForce(f: Force) {
    if (!this.forces.length) {
      logger.debug('add force', f);
    }
    this.forces.pop();
    this.forces.push(f);
  }

  public clearForces() {
    this.forces.length = 0;
  }

  public isPiecePartOfGroup(piece: Piece) {
    return this.jigsaw.groups.isPieceInAnyGroup(piece);
  }

  public playSound(url: string) {
    this.setState({ audio: url });

    if (this.audioRef.current) {
      this.audioRef.current.load();
      this.audioRef.current.play();
    }
  }

  public showHint() {
    const pieces = this.jigsaw.getHint();
    if (!pieces.length) {
      alert('You\'re done!');

      return;
    }

    document.querySelector('.container')?.classList?.remove('sidebar');

    pieces.forEach(p => this.jigsaw?.raisePieceToTop(p));

    this.frame(0, { loop: false });

    pieces.forEach(p => {
      this.jigsaw?.highlightPiece(this.renderer, p);
    });

    // @todo set up a constant for this delay
    setTimeout(() => {
      pieces.forEach(p => this.jigsaw?.markPieceDirty(p));
    }, 3000);
  }

  private onMobileMenu = (e: Event)  => {
    document.querySelector('.container')?.classList?.toggle('sidebar');
    e.preventDefault();
  }

  private bindDebugShortcuts() {
    const createDebugToggle = (setting: string) => {
      return () => {
        if (this.jigsaw) {
          this.jigsaw.drawMode.toggle(setting as JigsawDrawOption);
        }
      };
    };

    let i=1;
    this.eventHandler.registerShortcut(`d+${i++}`, createDebugToggle('showBounds'));
  }

  private exportDebugTools() {
    window.v = vectors;
    window.app = this;
    window.jigsaw = this.jigsaw;
  }

  private clipToUpdateRegion(renderer: AbstractRenderer) {
    if (renderer instanceof CanvasRenderer) {
      renderer.save();

      const path = new Path2D();
      const r = this.updateRegion.getRegion();
      path.rect(r.x, r.y, r.w, r.h);
      renderer.getContext().clip(path);
    }
  }

  private restoreClipRegion(renderer: AbstractRenderer) {
    if (renderer instanceof CanvasRenderer) {
      renderer.restore();
    }
  }

  private frame = (t: number, { loop = true } = {}) => {
    if (!(this.jigsaw && this.renderer)) {
      throw new Error('JigsawApp.frame found no valid jigsaw / renderer');
    }

    if (this.jigsaw.mode.isChanged || this.jigsaw.drawMode.isChanged) {
      this.updateRegion.invalidate();
    }

    // this.emit('beginFrame', this.renderer);
    this.emit('begin-physics', this.renderer);
    if (this.lastT < 0) {
      this.lastT = t;
    }
    this.runSweeperPhysicsStep((t - this.lastT) * PhysicsTimeScale);
    this.lastT = t;
    this.emit('end-physics', this.renderer);


    if (this.updateRegion.isDirty()) {
      this.clipToUpdateRegion(this.renderer);
      const rect = this.updateRegion.getRegion();
      this.renderer.fillStyle = this.state.backgroundColor;
      this.renderer.fillRect(rect.x, rect.y, rect.w, rect.h);
      // this.renderer.clearRect(0,0, this.canvas.width, this.canvas.height);

      this.jigsaw.draw(this.renderer);
      this.restoreClipRegion(this.renderer);
    }

    if (this.jigsaw.drawMode.showDirtyRect && this.renderer instanceof CanvasRenderer) {
      const context = this.renderer.getContext();
      this.updateRegion.debug(context);
    }

    // if (!this.jigsaw.drawMode.showDirtyRect) {
    this.updateRegion.clear();
    // }

    // this.emit('endFrame', this.renderer);

    if (loop) {
      this.animID = requestAnimationFrame(this.frame);
    }
  }

  private getCanvas() : HTMLCanvasElement {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      throw new Error(`No canvas found by ref`);
    }

    return canvas;
  }

  private handleShuffleClick = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    if (this.state.isLoading) {
      e.stopPropagation();

      return;
    }
    if (!this.state.isShuffled) {
      e.stopPropagation();
      this.shufflePieces();
      this.setState({ isShuffled: true });
    }
  }

  private calcRowsAndColumnsForNumPieces(numPieces: number, canvasDim: Readonly<Dim>) {
    const { floor, ceil, sqrt } = Math;
    const root = sqrt(numPieces);
    const shortSide = floor(root);
    const longSide = ceil(numPieces / shortSide);
    const rows =    canvasDim.isLandscape ? shortSide : longSide;
    const columns = canvasDim.isLandscape ? longSide : shortSide;

    return { rows, columns };
  }

  private getRowsAndColumns() {
    const { numPieces = this.state.numPieces } = this.getOptionsFromLocation();

    const canvas = this.getCanvas();
    const dim = new Dim(canvas);

    return this.calcRowsAndColumnsForNumPieces(numPieces, dim);
  }

  private getOptionsFromLocation() : ParsedLocationOptions {
    const params = new URLSearchParams(document.location.search.substring(1));
    const showPreview = params.get('sp');
    const numPieces = Number(params.get('np'));

    return {
      numPieces: (numPieces > 1 && !Number.isNaN(numPieces)) ? numPieces : undefined,
      showPreview: showPreview !== 'no',
    };
  }

  private loadNewImage() {
    const imageSource = getImageFromLocation();

    const loaders = [
      () : Promise<any> => {
        logger.debug('trying to load via url img');
        if (!imageSource) {
          throw new Error('No image to load');
        }

        return this.loadImage(imageSource);
      },
      () : Promise<any> => {
        logger.debug('trying to load via met collect');

        return this.loadMetImage();
      },
      () : Promise<any> => {
        logger.debug('trying to load via random');

        return this.loadImage(absolutePath(randomImage()));
      },
    ];

    return promiseFirst(loaders)
      .then((image: HTMLImageElement) => {
        // this.updateURL({ url: imageSource.url });
        this.start();
      })
      .catch(e => {
        if (getImageFromLocation()) {
          this.reportError(new Error(`Unable to load that image : ${e.message} ${e.stack}`));
        }
        this.reportError(e);
      });
  }

  private init() {
    const canvas = this.getCanvas();
    this.resized();
    logger.debug('canvas size', new vectors.Dim(canvas));

    this.initRenderer(canvas);
    this.initJigsaw(canvas);

    this.resized();

    this.eventHandler = new EventHandler(canvas, this.inputHandler);

    return this.loadNewImage();
  }

  private getJigsawRect() {
    const canvasDim = new Dim(this.getCanvas());
    const offset = canvasDim.times(CanvasMarginFactor / 2).floor();

    return canvasDim
      .scale(1 - CanvasMarginFactor)
      .toRect()
      .offset(offset.x, offset.y)
      .ceil();
  }

  private initJigsaw(canvas: HTMLCanvasElement) {
    const { rows, columns } = this.getRowsAndColumns();
    const { updateRegion } = this;

    this.setState({
      rows, columns,
      numPieces: rows * columns,
    });

    this.jigsaw = new Jigsaw({
      rows, columns,
      bounds: this.getJigsawRect(),
      updateRegion,
    });

    this.physics.apply(this, this.jigsaw);

    this.jigsaw.on('pieces-joined', (pieces: Piece[]) => {
      this.playSound(randomSound());
      this.physics.onResetPieces();
      this.scatterFromSelected(pieces);
    });
    this.jigsaw.on('cut-pieces', () => {
      this.emit('reset-pieces');
    });
    this.jigsaw.on('completed', (pieces: Piece[]) => {
      this.playSound(randomSuccessSound());
    });

    this.bindDebugShortcuts();
    this.exportDebugTools();
  }

  private initRenderer(canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d', { alpha: false });
    if (!context) {
      throw new Error('Unable to get render context from canvas');
    }
    this.renderer = new CanvasRenderer(context);
  }

  private loadImage(imageSource: string|File|ImageSource) : Promise<HTMLImageElement> {
    const canvas = this.getCanvas();
    const targetSize = this.getJigsawRect().dimensions;

    return setStatePromised(this, {
        isLoading: true,
        isShuffled: false,
      } as State,
    )
    .then(() => {
      logger.debug('loading image', imageSource, ' -> ', targetSize);

      return this.loader
      .loadAndScale(imageSource, targetSize);
    })
    .then(image => {
      const { showPreview } = this.getOptionsFromLocation();

      this.setState(state => ({
        image: { ...state.image,  url: image.src },
        isLoading: false,
      }));

      this.jigsaw.init(image);

      if (!showPreview) {
        this.shufflePieces();
      }

      return image;
    });
  }

  private onChangePieces = (numPieces: number) => {
    const canvas = this.getCanvas();
    const dim = new Dim(canvas);
    const { rows, columns } = this.calcRowsAndColumnsForNumPieces(numPieces, dim);

    this.setState({ isLoading: true, isShuffled: false, columns, rows}, () => {
      this.updateURL({ numPieces });
      this.jigsaw.setSize(columns, rows);
      this.setState({ isLoading: false });
      this.start();
    });
  }

  private updateURL(options: Partial<LocationOptions>) {
    this.setState({ location: { ...this.state.location, ...options }}, () => {
      const { url, showPreview, crop, numPieces } = this.state.location;
      const u =  url;
      const newURL = '?' + new URLSearchParams({
        u,
        sp: showPreview ? 'yes' : 'no',
        np: String(numPieces),
      });
      history.pushState(null, '', newURL.toString());
    });
  }

  private onChooseImage = (source: File|string) => {
    if (typeof (source) === 'string') {
      source = `${proxyURL}/image/?u=${encodeURIComponent(source)}`;
    }

    this.loadImage(source)
      .then(image => {
        this.jigsaw.setImage(image);
        this.start();
      })
      .catch(e => {
        alert(`Couldn't load that image, sorry.
${e.message ?? ''}`);
      });
  }

  private onSidebarToggleClick = () => {
    const { isSidebarHidden } = this.state;
    this.setState({ isSidebarHidden: !isSidebarHidden }, () => {
      this.resized();
    });
  }

  private reportError(e: Error) {
    logger.error('reportError', e, e.stack);
    this.setState({ lastError: e });
  }

  private resizeCanvas(canvas: HTMLCanvasElement) {
    canvas.width = canvas.offsetWidth - 2;
    canvas.height = canvas.offsetHeight - 2;
  }

  private resized() {
    const canvas = this.getCanvas();
    canvas.style.width='100%';
    canvas.style.height='100%';
    this.resizeCanvas(canvas);
    canvas.style.width='';
    canvas.style.height='';
    const bounds = this.getCanvasBounds();

    this.physics.stop();
    const constrainer = new Constrainer(bounds);
    this.jigsaw.perturb(constrainer);
    this.physics.setConstraintBounds(bounds);
    this.redraw();
  }

  private runSweeperPhysicsStep(dt: number) {
    if (!this.forces.length) {
      return;
    }
    const tSq = dt * dt;
    for (const piece of this.jigsaw) {
      if (this.jigsaw.groups.isPieceInAnyGroup(piece)) {
        continue;
      }
      const totalForce = calcForceForPiece(piece, this.forces, 200);
      totalForce.scale(tSq);
      this.jigsaw.movePieceBy(piece, totalForce.x, totalForce.y, { dirty: false });
    }
    this.jigsaw.markAsDirty();
    this.startPhysics();
  }

  private scatterFromSelected(pieces: Piece[]) {
    const scatter = new ScatterSelected(pieces);
    this.startPhysics();
    this.jigsaw.perturb(scatter);
  }

  private scatterPieces() {
    const scatter = new Scatterer();
    this.startPhysics();
    this.jigsaw.perturb(scatter);
  }

  private loadMetImage() {
    const authPromise : Promise<any> = hasAuthed ? Promise.resolve() : fetch(`${proxyURL}/auth/`, { credentials: 'include' });

    return setStatePromised(this,
      state => ({
        isLoading: true,
        isShuffled: false,
        image: {
          ...state.image,
          url: '',
        },
      } as State),
    )
    .then(() => authPromise)
    .then(() => {
      hasAuthed = true;

      return fetch(`${proxyURL}/random/`);
    })
    .then(response => response.json())
    .then(info => {
      const url = `${proxyURL}/image/?u=${encodeURIComponent(info.thumb)}`;

      const { title, url: link, ...rest } = info;

      this.setState(state => ({
        image: {
          ...state.image,
          title, link,
          metadata: rest,
        },
      }));

      return this.loadImage(url)
        .then(image => ({ ...info, imageURL: url, image }))
        .catch(error => {
          this.setState(state => ({
            image: {
              ...state.image,
              title: undefined,
              link: undefined,
              metadata: {},
            },
          }));
          throw new Error(`Unkown image load error: ${error.message}, ${error.stack}`);
        });
    });
  }

  private shufflePieces() {
    const shuffle = new Shuffler(this.getCanvasBounds());
    this.jigsaw.perturb(shuffle);
    this.physics.stop();
    this.setState({ isShuffled: true });
  }

  private start() {
    this.jigsaw.markAsDirty();
    cancelAnimationFrame(this.animID);
    this.frame(0);
  }
}
