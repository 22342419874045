/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Point, Rect } from '@/geom';
import { randRangeFloor } from '@/util';

import type { IJigsaw } from '@/jigsaw';
import type { Immutable } from '@/jtypes';
import type { Piece } from '@/piece';
import type { Perturber } from './types';

import * as logger from '@/logger';

type Bucket = Piece[];

export class GridScatterer implements Perturber {
  private static readonly MaxInBucket = 5;

  private readonly buckets: {
    x: Bucket[];
    y: Bucket[];
  } = {
    x: [],
    y: [],
  };

  private grid = new Point(0, 0);

  constructor(private bounds: Immutable<Rect>, private numBoxes: number) {
    bounds.inset(20);
    this.grid.x = bounds.w / numBoxes;
    this.grid.y = bounds.h / numBoxes;
    this.buckets.x.length = numBoxes;
    this.buckets.y.length = numBoxes;
  }

  public run(pieces: Piece[], jigsaw: IJigsaw) : void {
    jigsaw.on('post-draw', (renderer) => {
      renderer.strokeStyle = 'pink';
      for (let i = 0; i < this.numBoxes; i++) {
        for (let j = 0; j < this.numBoxes; j++) {
          renderer.strokeRect(
            j * this.grid.x,
            i * this.grid.y,
            this.grid.x,
            this.grid.y,
          );
        }
      }
    });

    for(const piece of pieces) {
      let xIdx = Math.floor((piece.getPos().x - this.grid.x/2) / this.grid.x);
      let yIdx = Math.floor((piece.getPos().y - this.grid.y/2) / this.grid.y);
      xIdx = Math.max(0, Math.min(this.numBoxes-1, xIdx));
      yIdx = Math.max(0, Math.min(this.numBoxes-1, yIdx));
      this.buckets.x[xIdx] = this.buckets.x[xIdx] || [];
      this.buckets.x[xIdx].push(piece);
      this.buckets.y[yIdx] = this.buckets.y[yIdx] || [];
      this.buckets.y[yIdx].push(piece);
    }
    logger.debug('buckets, pre-distribute', this.buckets);
    this.distribute(jigsaw, this.buckets.x);
    this.distribute(jigsaw, this.buckets.y, false);
  }

  private distribute(jigsaw: IJigsaw, buckets: Bucket[], isHorizontal = true) {
    for (let i=0; i < buckets.length; i++) {
      const bucket = buckets[i];

      if (!bucket/* || bucket.length < GridScatterer.MaxInBucket*/) {
        continue;
      }
      logger.debug(`bucket ${i} length is ${bucket.length}`);

      let tries = 0;

      while (tries < 10 && bucket.length > GridScatterer.MaxInBucket) {
        const idx = randRangeFloor(0, bucket.length);
        const piece = bucket[idx];
        const sparseIdx = buckets.findIndex(b => b === undefined || b.length < GridScatterer.MaxInBucket - 1);
        if (sparseIdx >= 0) {
          logger.debug(`moving ${piece.id} from bucket ${i} to ${sparseIdx}`);
          buckets[sparseIdx] = buckets[sparseIdx] || [];
          buckets[sparseIdx].push(piece);
          bucket.splice(idx, 1);
        } else {
          bucket.splice(idx, 1);
          const bIdx = randRangeFloor(0, buckets.length);
          buckets[bIdx].push(piece);
          logger.debug(`using random bucket ${bIdx} for ${piece.id} from bucket ${i}`);
          ++tries;
        }
      }
    }

    logger.debug(`buckets, post-dist`, this.buckets);


    for (let i=0; i < buckets.length; i++) {
      const bucket = buckets[i];

      if (!bucket/* || bucket.length < GridScatterer.MaxInBucket*/) {
        continue;
      }

      for (const piece of bucket) {
        const mul = isHorizontal ? this.grid.x : this.grid.y;

        const pos = i * mul + mul/2 + randRangeFloor(-mul/4, mul/4);
        if (isHorizontal) {
          jigsaw.movePieceTo(piece, pos, piece.getPos().y, {  dirty: false });
        } else {
          jigsaw.movePieceTo(piece, piece.getPos().x, pos, {  dirty: false });
        }
      }
    }
  }
}

