/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import { AppCommand } from './appCommand';

export class HintCommand extends AppCommand {

  constructor() {
    super();
  }

  public run(app: ReactJigsaw) {
    app.showHint();
  }
}
