/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import type { Immutable } from '@/jtypes';
import type { ICommand, Named } from './types';

export class BaseCommand implements Named {
  public name: string;
  private _hasRun = false;

  constructor() {
    this.name = this.constructor.name;
  }

  get hasRun() { return this._hasRun; }

  public markAsFinished() : void {
    this._hasRun = true;
  }

  public equals<C>(other: Immutable<ICommand<C>>) : boolean {
    return false;
  }
}
