/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as React from 'react';

type Props = {
  name?: string;
  children?: React.ReactNode;
};

export type SlotType = Props & { isSlot: true };

export const Slot = ({ name }: Props) => {
  if (name === 'children') {
    throw new Error('You cannot name a slot "children", this will break stuff');
  }

  return null;
};

export type ParsedSlots = {
  [key: string] : React.ReactNode;
  children: React.ReactNode[];
};

Slot.parse = (children?: React.ReactNode) => {
  const slotMap : ParsedSlots = { children: [] };

  React.Children.forEach(children, child => {
    const elt = child as React.ReactElement;
    if (elt && elt.type === Slot) {
      const { name, children: slotChildren } = elt.props;
      slotMap[name || 'default'] = slotChildren;
    } else {
      slotMap.children.push(child);
    }
  });

  return slotMap;
};
