/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { AppCommand } from './appCommand';

import type { ReactJigsaw } from '@/components/ReactJigsaw';
import type { Force } from '@/pusher';

export class PhysicsForceCommand extends AppCommand {
  constructor(public force: Force|null, public clearAll = false) {
    super();
  }

  public run(app: ReactJigsaw) {
    if (this.clearAll) {
      app.clearForces();
    } else if (this.force) {
      app.addForce(this.force);
    }
  }
}
