/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

type JigsawDebugOptions = {
  onlyDrawPath: boolean;
  showBounds: boolean;
  showConstruction: boolean;
  showDirtyRect: boolean;
  showExtents: boolean;
  showIDs: boolean;
  showPath: boolean;
};

export type JigsawDrawOption =  keyof JigsawDebugOptions;

export class JigsawDrawMode {
  private settings: { [key: string] : boolean } = {
    onlyDrawPath: false,
    showBounds: false,
    showConstruction: false,
    showDirtyRect: false,
    showExtents: false,
    showIDs: false,
    showPath: false,
  };

  constructor(private _isChanged = false) {
  }

  get isChanged() { return this._isChanged; }


  get onlyDrawPath() { return this.settings.onlyDrawPath; }
  get showBounds() { return this.settings.showBounds; }
  get showConstruction() { return this.settings.showConstruction; }
  get showDirtyRect() { return this.settings.showDirtyRect; }
  get showExtents() { return this.settings.showExtents; }
  get showIDs() { return this.settings.showIDs; }
  get showPath() { return this.settings.showPath; }

  public toggle(name: string) : void {
    this.settings[name as string] = !this.settings[name as string];
    this._isChanged = true;
  }

  public set(name: string, value: boolean) : void {
    this.settings[name] = value;
    this._isChanged = true;
  }

  public unflag() : void {
    this._isChanged = false;
  }
}
