/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as React from 'react';

import type { Indexable } from '@/jtypes';
import type { TongueParams } from '@/tongue';

import { Slot } from '@/components/Slot';

import styles from '@/styles/controls.scss';

export type ControlsProps =
Partial<{
  settings: TongueParams;
  backgroundColor: string;
  rows: number;
  columns: number;
  image: string|false;
  enabled: boolean;
  children: React.ReactNode;
  onChooseImage(source: File|string) : void;
  onChangePieces(numPieces: number) : void;
  onChangeColor(color: string) : void;
}>;

type ControlsState = {
  pieces: string;
};

// state is never set so we use the '{}' type.
export class Controls extends React.Component<ControlsProps, ControlsState> {

  constructor(props: ControlsProps) {
    super(props);
    const { rows=0, columns=0 } = props;
    this.state = { pieces: String(rows * columns) };
  }

  public render() {
    const {
      rows = 0,
      columns = 0,
      enabled = false,
      image = false,
      backgroundColor = 'white',
      children,
    } = this.props;

    const numPieces = this.state.pieces;
    const slots = Slot.parse(children);

    return (
      <>
        {slots.default}
        <div className={styles.infoInner}>
          {slots.preview}
          <label className='field--numPieces'>
            <span className={styles.labelText}>Number of pieces</span>
            <input
              onChange={e => {
                this.setState({ pieces: e.target?.value });
              }}
              onBlur={this.onChangePieces}
              onKeyUp={this.handleEnterKey}
              type='text'
              pattern='[0-9]*'
              inputMode='decimal'
              id='numPieces'
              size={6}
              value={numPieces}
            />
          </label>

          <label>
            <span>Choose your own image</span>
            <div className={styles.customImage}>
              <label title='Choose a picture from your computer' htmlFor='image'
              ><input
                onChange={this.handleFile}
                accept='image/jpeg'
                type='file'
                id='image'
                />
              </label>
            </div>
            <button title='Enter the web address of an image online' onClick={this.askURL}>URL</button>
          </label>
        </div>

        {/*<label>
          <button
            disabled={!enabled}
            onClick={this.handleAction('debug')}>Debug
          </button>
        </label>*/}

        <div className='backgrounds'>
          <label>Change background</label>
          <div className={styles.backgrounds}>
            {this.colorButtons(backgroundColor)}
          </div>
        </div>

        {/*<a download="test.png" id="test">Download backing image</a>*/}

        <div className={styles.tips}>
          <h4>Tips</h4>
          <ul>
          <li>🖱️<code className={styles.mouse}> Right-click</code> to reach pieces underneath</li>
          <li><code className={styles.mouse}><kbd>Shift</kbd>+ 🖱️ drag</code> to push pieces around</li>
          </ul>
        </div>

        {slots.children}
      </>
    );
  }

  private onChangePieces = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.onChangePieces) {
      return;
    }
    const { pieces: piecesText } = this.state;
    const pieces = Number(piecesText);
    const { rows = 0, columns = 0 } = this.props;
    const currentNumPieces = rows * columns;

    if (!pieces || pieces < 2 || pieces === currentNumPieces) {
      return;
    }

    if (pieces > 10000) {
      alert('More than 10000 pieces is ridiculous.  In any case they\'ll be too small to see!');

      return;
    }

    if (confirm('Change number of pieces? This will reset the board')) {
      this.props.onChangePieces(pieces);
    }
  }

  private handleEnterKey =  (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement)?.blur();
    }
  }

  private askURL = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!this.props.onChooseImage) {
      return;
    }
    const url = prompt(`Enter the address of the image.
This may not work for many sites due to web security settings`);
    if (url) {
      this.props.onChooseImage(url);
    }
  }

  private handleFile = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    const { onChooseImage } = this.props;
    if (onChooseImage && files?.length) {
      onChooseImage(files[0]);
    }
  }

  private colorButtons(backgroundColor: string) {
    const setBG = (c: string, tc: string) => {
      document.body.style.background = c;
      const canvas = document.querySelector('canvas');
      if (canvas) {
        canvas.style.background = c;
      }
      document.body.style.color = tc;
      if (this.props.onChangeColor) {
        this.props.onChangeColor(c);
      }
    };

    const textColors = [
      'white',
      'white',
      'white',
      'black',
      'black',
      'black',
      'white',
      'white',
      'white',
      'white',
      'white',
    ];

    return [
      'black',
      '#333',
      '#666',
      '#999',
      'gainsboro',
      'white',
      '#346D36',
      '#6D6434',
      '#6E3437',
      '#50346E',
      '#34546E',
    ].map((c,i) => {
      const style = {
        '--var-bg': c,
        'color': (i < 3 ? 'white' : 'black'),
      };

      return (
        <button key={c}
          onClick={() => setBG(c, textColors[i])}
          className={`${styles.colorbutton} ${c === backgroundColor ? styles.colorbuttonSelected : ''}`}
          style={style}
        />
      );
    }).concat(
      <input type='color'
        onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => setBG((e.target as HTMLInputElement).value, 'white')}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setBG(e.target.value, 'white')}
        className={`${styles.colorbutton} ${styles.colorbuttonInput}`}
      />,
    );
  }
}
